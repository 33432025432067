import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {  ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PulseApiSettingsService } from '@core/pulse-api/pulse-api-settings.service';
import { ControlWrapperComponent } from './components/control-wrapper/control-wrapper.component';
import { PredicatePipe } from './pipes/predicate.pipe';
import { PasswordComponent } from './components/password/password.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    ControlWrapperComponent,
    PasswordComponent,
    PredicatePipe,
    SafeHtmlPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
  ],
  exports: [
    ControlWrapperComponent,
    PasswordComponent,
  ],
  providers: [
    PulseApiSettingsService,
  ]
})
export class CoreModule { }
