import { Injectable } from '@angular/core';
import { max, padStart } from 'lodash';

@Injectable()
export class ReferenceGeneratorService {

    incrementalReference(existingReferences: string[], length: number): string {
      const referenceNumbers = existingReferences
        .map(ref => parseInt(ref.substring(0, length), 10))
        .filter(ref => !isNaN(ref));
      const nextReferenceNumber = (max(referenceNumbers) || 0) + 1 || 1;
      return padStart(nextReferenceNumber.toString(), length, '0');
    }
}
