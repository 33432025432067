import { Type } from "@angular/core";
import { ModalOptions } from "ngx-bootstrap/modal";

/**
 * This class is used to create a modal dialog.
 *
 * @export
 * @class AppDialogContext
 */
export class AppDialogContext  extends ModalOptions {
    childComponent?: Type<any>;

    title: string = '';
    body: string = '';
    okBtnText?: string;
    cancelBtnText?: string;
    cancelBtnClass?: string;
    dialogClass?: string;
    footerClass?: string;
    headerClass?: string;
    okBtnClass?: string;
    showHeaderClose?: boolean;
    showOnlyCloseBtn?: boolean;

    private static getDefault(): AppDialogContext {
        return {
            title: '',
            body: '',
            okBtnText: 'Yes',
            cancelBtnText: 'No',
            okBtnClass: 'btn button-primary',
            cancelBtnClass: 'btn btn-default',
            headerClass: 'confirm-modal-header',
            footerClass: 'confirm-modal-footer',
            dialogClass: 'modal-dialog',
            showHeaderClose: true,
            showOnlyCloseBtn: false
        };
    }

    /**
     * Generates a modal config file
     *
     * @static
     * @param {Object} config
     * @returns {AppDialogContext}
     * @memberof AppDialogContext
     */
    public static generate(context: Partial<AppDialogContext>, config: Object = {}): AppDialogContext {
      const newConfig = AppDialogContext.getDefault();
      const mergedConfig = { ...newConfig, ...config, ...context };
      console.log('Merged config:', mergedConfig); // Add this log
      return mergedConfig as AppDialogContext;
    }
}
