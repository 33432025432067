
import { Address } from '@src/app/models/address';
import { BaseAuditModel } from "./baseAuditModel";
export class UserProfile extends BaseAuditModel {
  userId: string = '';
  firstName: string = '';
  lastName: string = '';
  profileImageKey: string = '';
  addressId: number = 0;
  address: Address = new Address();
  displayName: string = '';
  email: string = '';
  phoneNumber: string = '';
  agencyId: string = '';
}
