import { Injectable } from '@angular/core';
import { Lookup } from '@src/app/models/lookup';
import {  of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PropertyDetailsDropdown } from '@core/types/property-details-dropdown';
import { TenancyDetailsDropdown } from '../types/tenancy-details-dropdowns';
import { PropertyCertificateDropdown } from '../types/property-certificate-dropdowns';
import { MaintenanceDropdown } from '../types/property-maintenance-dropdowns';
import { BaseApiService } from './base-api.service';
import { LookupTableLookup } from '../enums/LookupTableLookup';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {

  // property dropdowns
  styleList: Lookup[] = [];
  propertyStatusLevel: Lookup[] = [];
  departmentLookups: Lookup[] = [];
  statusList: Lookup[] = [];
  rentalContractList: Lookup[] = [];
  agencyCommissionTypeList: Lookup[] = [];
  tenureList: Lookup[] = [];
  councilTaxBandList: Lookup[] = [];
  managementTypeList: Lookup[] = [];
  furnitureList: Lookup[] = [];
  priceTypeList: Lookup[] = [];
  rentFrequency: Lookup[] = [];
  typeList: Lookup[] = [];

  // tenancy dropdowns
  initialTermOptions: Lookup[] = [];
  leaseTypeOptions: Lookup[] = [];
  termTypeOptions: Lookup[] = [];
  statusOptions: Lookup[] = [];

  //property certifcate dropdowns
  certificateTypeOptions: Lookup[]= [];
  EPCRating: Lookup[]= [];
  certificateStatusOptions: Lookup[]= [];

  //maintenance dropdowns
  maintenanceJobStatus: Lookup[] = [];
  maintenanceJobReportedBy: Lookup[] = [];

  public static isInitialised : boolean ;//= false;
  static allLookups:any = {};

  constructor(private _baseService: BaseApiService) {

    if(!DropdownService.isInitialised){
      this.initialise()
      DropdownService.isInitialised    }
    // initialize and load all dropdown arrays
    // this.loadPropertyDetailsTabDropdowns();
    // this.loadTenancyDropdowns();
    // this.loadPropertyCertificateDropdowns();
    // this.loadMaintenaceDropdowns();
  }

  initialise(): Observable<boolean> {
    const url = '/api/lookup/all';
    if(!DropdownService.isInitialised){
    this._baseService.get<any>(url).subscribe((lookups) => {
      DropdownService.allLookups = lookups;
      DropdownService.isInitialised = true;

       // initialize and load all dropdown arrays
    this.loadPropertyDetailsTabDropdowns();
    this.loadTenancyDropdowns();
    this.loadPropertyCertificateDropdowns();
    this.loadMaintenaceDropdowns();
    });
  }
    return of(true);
  }



  loadTenancyDropdowns(): void {
    this.initialTermOptions = [
      { value: '3', text: '3 Months' },
      { value: '6', text: '6 Months' },
      { value: '12', text: '12 Months' },
      { value: '24', text: '24 Months' },
      { value: '36', text: '36 Months' },
    ];
    this.leaseTypeOptions = [
      { value: '1', text: 'AST (Assured Shorthold Tenancy)' },
      { value: '2', text: 'Assured Tenancy' },
      { value: '3', text: 'Lease' },
    ];
    this.termTypeOptions = [
      { value: '1', text: 'Weeks' },
      { value: '2', text: 'Months' },
    ];
    this.statusOptions = [
      { value: '1', text: 'References Pending' },
      { value: '2', text: 'Let Agreed' },
      { value: '3', text: 'Let' },
      { value: '4', text: 'On Hold' },
      { value: '5', text: 'Finished' },
      { value: '6', text: 'Cancelled' },
    ];
  }

  getTenancyDropdowns(): Observable<TenancyDetailsDropdown> {

    return of<TenancyDetailsDropdown>({
      initialTermOptions: this.initialTermOptions,
      leaseTypeOptions: this.leaseTypeOptions,
      termTypeOptions: this.termTypeOptions,
      statusOptions: this.statusOptions,
      rentFrequency: this.rentFrequency, // Add the missing rentFrequency property
    }).pipe(
      map((lookups) => {
        // Transform the data if needed or just pass it through
        return lookups;
      })
    );
  }

  getPropertyDetailsTabDropdowns(): Observable<PropertyDetailsDropdown> {
    // Assuming that the lookups are fetched from a service, we should return an Observable
    // Here's a mock example using of() to simulate an Observable that emits the lookups
    return of({
      propertyStatusLevel: this.propertyStatusLevel,
      departmentLookups: this.departmentLookups,
      statusList: this.statusList,
      rentalContractList: this.rentalContractList,
      agencyCommissionTypeList: this.agencyCommissionTypeList,
      tenureList: this.tenureList,
      councilTaxBandList: this.councilTaxBandList,
      managementTypeList: this.managementTypeList,
      furnitureList: this.furnitureList,
      priceTypeList: this.priceTypeList,
      rentFrequency: this.rentFrequency,
      typeList: this.typeList,
      styleList: this.styleList,
    }).pipe(
      map((lookups) => {
        // Transform the data if needed or just pass it through
        return lookups;
      })
    );
  }

  getPropertyCertificateDropdowns(): Observable<PropertyCertificateDropdown> {

    return of<PropertyCertificateDropdown>({

      certificateTypeOptions: this.certificateTypeOptions,
      EPCRating: this.EPCRating,
      certificateStatusOptions: this.certificateStatusOptions,
    }).pipe(
      map((lookups) => {
        // Transform the data if needed or just pass it through
        return lookups;
      })
    );
  }

  getMaintenanceDropdowns(): Observable<MaintenanceDropdown> {

    return of<MaintenanceDropdown>({
      maintenanceJobStatus: this.maintenanceJobStatus,
      maintenanceJobReportedBy: this.maintenanceJobReportedBy,
    }).pipe(
      map((lookups) => {
        // Transform the data if needed or just pass it through
        return lookups;
      })
    );
  }

  getMessageGroupDropdowns(): Observable<MaintenanceDropdown> {

    return of<MaintenanceDropdown>({
      maintenanceJobStatus: this.maintenanceJobStatus,
      maintenanceJobReportedBy: this.maintenanceJobReportedBy,
    }).pipe(
      map((lookups) => {
        // Transform the data if needed or just pass it through
        return lookups;
      })
    );
  }

  getDropdownsByKey(key: LookupTableLookup):Lookup[] {

    try{
      let enumAsString = LookupTableLookup[key]
      var lookupKey =  enumAsString.charAt(0).toLowerCase() + enumAsString.slice(1); //make the first letter lowercase
      var lookups = DropdownService.allLookups[lookupKey] as Lookup[] || [];
    return lookups;

    }catch(error){
      console.log('Error in DropdownService.getDropdownsByKey', error);
    }

    return [];

  }

  // this method will retreive/populate the PropertyCertificateDropdown
  loadPropertyCertificateDropdowns():void{

    this.certificateTypeOptions = this.getDropdownsByKey(LookupTableLookup.PropertyCertificateType);
    this.certificateStatusOptions = this.getDropdownsByKey(LookupTableLookup.PropertyCertificateStatus);
    this.EPCRating = this.getDropdownsByKey(LookupTableLookup.PropertyCertificateEPCRating);
    return;

    this.certificateTypeOptions = [
      { value: '1', text: 'Gas Safety Certificate' },
      { value: '2', text: 'Electrical Safety Certificate' },
      { value: '3', text: 'HMO License' },
      { value: '4', text: 'Energy Performance Certificate (EPC)' },
      { value: '5', text: 'Other' },
    ];

        this.certificateStatusOptions = [
            { value: 'C', text: 'Complete' },
            { value: 'I', text: 'Incomplete' },
            { value: 'P', text: 'Pending' },
            { value: 'E', text: 'Expired' },
          ];

  this.EPCRating = [
      { value: 'A', text: 'A' },
      { value: 'B', text: 'B' },
      { value: 'C', text: 'C' },
      { value: 'D', text: 'D' },
      { value: 'E', text: 'E' },
      { value: 'F', text: 'F' },
      { value: 'G', text: 'G' },
    ];


  }


  loadPropertyDetailsTabDropdowns(): void {
    this.propertyStatusLevel = [
      { value: '1', text: 'To Let Pending' },
      { value: '2', text: 'References Pending' },
      { value: '3', text: 'Let Agreed/Let By' },
      { value: '4', text: 'Let' },
      { value: '5', text: 'Withdrawn' },
      /* populate with Lookups */
    ];
    this.departmentLookups = [
      { value: '1', text: 'Sales' },
      { value: '2', text: 'Lettings' },
      { value: '3', text: 'Students' },
      { value: '4', text: 'Commercial' },
      { value: '5', text: 'Holidays' },
      /* populate with Lookups */
    ];
    this.statusList = [
      { value: '1', text: 'Pending' },
      { value: '2', text: 'Active' },
      { value: '3', text: 'Inactive' },
      /* populate with strings */
    ];
    this.rentalContractList = [
      { value: '1', text: 'Long Term' },
      { value: '2', text: 'Short Term' },
      { value: '3', text: 'Commercial' },
      /* populate with strings */
    ];
    this.agencyCommissionTypeList = [
      { value: '1', text: '% of Rent Value' },
      { value: '2', text: 'Set Monthly Fee' },
      { value: '3', text: 'One Off Fee' },
      /* populate with strings */
    ];
    this.tenureList = [
      { value: '1', text: 'Leasehold' },
      { value: '2', text: 'Freehold' },
      { value: '3', text: 'Share of Freehold' },
      { value: '4', text: 'Private Company/Ownership' },
      { value: '5', text: 'Unknown' },
      /* populate with strings */
    ];
    this.councilTaxBandList = [
      { value: '1', text: 'A' },
      { value: '2', text: 'B' },
      { value: '3', text: 'C' },
      { value: '4', text: 'D' },
      { value: '5', text: 'E' },
      { value: '6', text: 'F' },
      { value: '7', text: 'G' },
      { value: '8', text: 'H' },
      /* populate with strings */
    ];
    this.managementTypeList = [
      { value: '1', text: 'Fully Managed' },
      { value: '2', text: 'Let Only' },
      { value: '3', text: 'Rent Collection' },
      { value: '4', text: 'Let 2 Let' },
      /* populate with strings */
    ];
    this.furnitureList = [
      { value: '1', text: 'Furnished' },
      { value: '2', text: 'Un-Furnished' },
      { value: '3', text: 'Part Furnished' },
      { value: '4', text: 'Landlord Flexible' },
      /* populate with strings */
    ];
    this.priceTypeList = [
      { value: '1', text: 'Fixed' },
      { value: '2', text: 'Negotiable' },
      { value: '3', text: 'Offers Over' },
      { value: '4', text: 'Guide Price' },
      /* populate with strings */
    ];
    this.rentFrequency = [
      { text: 'Weekly', value: '1' },
      { text: 'Fortnightly', value: '2' },
      { text: '4 Weekly', value: '3' },
      { text: 'PCM', value: '4' },
      { text: 'Quarterly', value: '5' },
      { text: 'Yearly', value: '6' },
    ];

    this.typeList = [
      { value: '1', text: 'Apartment/Studio' },
      { value: '2', text: 'Building Plot' },
      { value: '3', text: 'Bungalow' },
      { value: '4', text: 'Farm' },
      { value: '5', text: 'Small Holding' },
      { value: '6', text: 'Flat' },
      { value: '7', text: 'House' },
      { value: '8', text: 'Town House' },
      /* populate with strings */
    ];
    this.styleList = [
      { value: '1', text: 'N/A' },
      { value: '2', text: 'Terraced' },
      { value: '3', text: 'Semi-Detached' },
      { value: '4', text: 'Detached' },
      { value: '5', text: 'End of Terrace' },
      { value: '6', text: 'Mews' },
      /* populate with strings */
    ];
  }

  loadMaintenaceDropdowns(){

    this.maintenanceJobStatus = [
      { value: '1', text: 'New' },
      { value: '2', text: 'Assigned' },
      { value: '3', text: 'In Progress' },
      { value: '4', text: 'Compleed' },
      { value: '5', text: 'Abandoned' },
    ];

    this.maintenanceJobReportedBy = [
      { value: '1', text: 'Tenant' },
      { value: '2', text: 'Landlord' },
      { value: '3', text: 'Agent' },
      { value: '4', text: 'Third Party' },
    ];

  }

}
