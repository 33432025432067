import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Make sure FormsModule is imported

import {UserIdentityService} from './services/user-identity.service';
import {AuthenticationEventsService } from './services/authentication-events.service';
import { AuthRoutingModule  } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
  ],

  providers: [
    UserIdentityService,
    AuthenticationEventsService
  ],
  imports: [
    CommonModule,
    FormsModule, // Make sure FormsModule is imported
    AuthRoutingModule
  ]
})
export class AuthModule { }
