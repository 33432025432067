import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoggedOutLayoutComponent } from './layout/logged-out-layout.component';

@NgModule({
  declarations: [
    LoggedOutLayoutComponent
  ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [
    LoggedOutLayoutComponent,
  ]
})
export class LoggedOutLayoutModule { }
