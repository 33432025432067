import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Event } from '../../utility';

// A service exposing events relating to authentication.
@Injectable()
export class AuthenticationEventsService {

    loginRequiredEvent = new Event<{ expired: boolean }>();

    userUpdatedEvent = new Event<User | undefined>(new ReplaySubject<User | undefined>(1));
}
