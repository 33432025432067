// Definition: Enum for FileUploadArea
// The classification/type of image/file is uploaded to for storage and retrieval
export enum FileUploadArea
    {
        General = 0,
        Property = 1,
        FloorPlan = 2,
        EPC = 3,
        Inspection = 4,
        ElectricCertificate = 5,
        GasCertificate = 6,
        Landlord = 7,
        Vendor = 8,
        Tenancy = 9,
        Tenant = 10,
        PropertyDetails = 11,
        //LettingsProperty = 11,
        //SalesProperty = 12,
        Contact = 13,
        Contractor = 14,
        Applicant = 15,
        Buyer = 16,
        WordTemplates = 17,
        Maintenance = 18,
        UserProfile = 19,
        ChatMessage = 20,
    }
