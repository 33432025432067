<div class=" justify-content-center text-center  mt-4 row">
  <h2 class="col-12">
    <strong>Contact us</strong>
  </h2>
</div>

<div class="row justify-content-center mt-4">
  <div class="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
    <form [formGroup]="form" novalidate="novalidate">

      <control-wrapper name="fullName" label="Full name" [control]="form.controls['fullName']" [validationErrors]="getValidationMessages('fullName')">
        <input class="form-control" type="text" formControlName="fullName"
          [class.is-invalid]="isControlInvalid('fullName')">
      </control-wrapper>

      <control-wrapper name="email" label="Email" [control]="form.controls['email']" [validationErrors]="getValidationMessages('email')">
        <input class="form-control" type="text" formControlName="email"
          [class.is-invalid]="isControlInvalid('email')">
      </control-wrapper>

      <control-wrapper name="telephone" label="Contact number" [control]="form.controls['telephone']" [validationErrors]="getValidationMessages('telephone')">
        <input class="form-control" type="text" formControlName="telephone"
          [class.is-invalid]="isControlInvalid('telephone')">
      </control-wrapper>

      <control-wrapper name="message" label="Message" [control]="form.controls['message']" [validationErrors]="getValidationMessages('message')">
        <textarea class="form-control" type="text" formControlName="message" rows="10"
          [class.is-invalid]="isControlInvalid('message')"></textarea>
      </control-wrapper>


      <button type="button" class="btn btn-primary m-3" (click)="onSubmit()">
        <i class="fa"></i>
        Send
      </button>
    </form>
