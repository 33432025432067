export class APIResponse<T> {
    isSuccess: boolean = false;
    hasErrors: boolean = false;
    errors: string[] = [];
    messages: string[] = [];
    data: T | Error ={} as T

    constructor(response: T | Error) {
      if (response instanceof Error) {
          this.isSuccess = false;
          this.hasErrors = true;
          this.errors = [response.message];
          this.data = response; // Assign the error to data
      } else {
          this.isSuccess = true;
          this.data = response; // Assign the successful response to data
      }
  }
}
