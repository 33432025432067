import { Moment } from 'moment';

export class User {
     roles: string[] = [];
     claims: string[] = [];
     userName: string = '';
     deviceId: string = '';
     accessToken: string = '';
     lastLogin: string = '';
     displayName: string = '';
     userId: string = '';
     expiryTime: string = '';
     isLandlord() {return this.roles.includes('landlord')};
     isTenant() {return this.roles.includes('tenant')};
}
