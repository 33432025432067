<pre class="card card-block card-header">Model: {{ searchControl.value | json }}</pre>

<input [formControl]="searchControl"
       typeaheadOptionField="login"
       [typeahead]="suggestions$"
       [typeaheadAsync]="true"
       class="form-control"
       placeholder="Enter GitHub username">

<div class="alert alert-danger" role="alert" *ngIf="errorMessage">
  {{ errorMessage }}
</div>
