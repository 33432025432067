import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-logged-out',
  templateUrl: './logged-out-layout.component.html',
  styleUrls: ['./logged-out-layout.component.css']
})
export class LoggedOutLayoutComponent {

}
