// Purpose: Guard to check if user is a landlord.
// Usage: Used to protect routes that should only be accessed by landlords.

import { CanActivate, Router, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LandlordGuard implements CanActivate {
  constructor(private authService: UserService, private router: Router) {}
  canActivate(): boolean  | UrlTree {
    if (!this.authService.hasRole(['landlord'])) {
      return true;
      // this is not working at the moment
      return this.router.parseUrl('/not-authorised');
    }
    return true;
  }
}
