import { AppSettings } from "@core/types/app-settings.interface";

export const environment: AppSettings = {
  production: false,
  selectEmptyOption: 'Select an option',
  apiBaseUri: 'https://api.thelandlordportal.co.uk',
  loginUri: 'https://api.thelandlordportal.co.uk/api/account/login',
  // apiBaseUri: 'https://api.thelandlordportal.co.uk',
  // selectEmptyOption: 'Select an option',
  // loginUri: 'https://api.thelandlordportal.co.uk/account/login',
};
