import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppCustomPreloadingStrategy } from './app-routing.preload-strategy';
import { TenantGuard } from './core/guards/tenant.guard';
import { LandlordGuard } from './core/guards/landlord.guard';
import { UserIdentityService } from './core/authentication/services/user-identity.service';
import { User } from './core/authentication/models/user';
import { NotAuthorisedComponent } from './shared/views/not-authorised/not-authorised.component';

const routes: Routes = [
  {
    path: 'landlord',
    loadChildren: () => import('./landlord/landlord.module').then(m => m.LandlordModule),
    canActivate: [LandlordGuard]
  },
  {
    path: 'tenant',
    loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule),
    canActivate: [TenantGuard]
  },
  {
    path: '',
    loadChildren: () => import('./landlord/landlord.module').then(m => m.LandlordModule),
    data: {preloadStrategy: 'maintenance'}
    // canActivate: [LandlordGuard]
    // component: RoleSelectionComponent
  },
];


@NgModule({

  // providers: [UserService],
  providers: [AppCustomPreloadingStrategy], // Provide the strategy here
  imports: [
    RouterModule.forRoot(routes, { useHash: false, bindToComponentInputs : true})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  currentUser: User | undefined;
  constructor(
    private userIdentityService: UserIdentityService,
   ) {
    this.currentUser = userIdentityService.currentUser();
  }
 }
