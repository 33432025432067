import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalDialogComponent } from '@shared/components/modal/modal-dialog/modal-dialog.component';
import { AppDialogContext } from '@src/app/shared/models/app-modal-context';
import { AddEditTenancyComponent } from '@landlord/features/tenancy/components/add-edit-tenancy/add-edit-tenancy.component';
import { AppModalConfig } from '../../models/app-modal-config';
import { Router } from '@angular/router';

@Component({
  selector: 'add-tenancy-button',
  templateUrl: './add-tenancy-button.component.html',
  styleUrls: ['./add-tenancy-button.component.css']
})
export class AddTenancyButtonComponent implements OnInit {

  @Input() propertyId: number = 0;
constructor(

  private router: Router,
  public modalService: BsModalService,
){}

ngOnInit(){

}

// addTenancy(){
//     const initialState: Partial<AppDialogContext> = {
//         childComponent: AddTenancyComponent,
//         okBtnText: 'Save', // Example text, adjust as needed
//         cancelBtnText: 'Cancel', // Example text, adjust as needed
//         showHeaderClose: true,
//         showOnlyCloseBtn: false,
//     footerClass: 'custom-footer-class' // Example class, adjust as needed
//   };
//   const modal = this.modalService.show(ModalDialogComponent);
    // initalState : context);
    // {
    // initialState: context as AppDialogContext,
    //   ...AppDialogContext.generate(context)
    // }
  // });
// addTenancy(){
//   const context: AppDialogContext = AppDialogContext.generate({ childComponent: AddTenancyComponent });
//   // context.childComponent = AddTenancyComponent;
//   // const modalOptions: ModalOptions = { initialState: AppDialogContext.generate({ childComponent: AddTenancyComponent }) }
//   const modal = this.modalService.show(ModalDialogComponent,  context );
  // Here the component subscribes to when the modal is closed


  addTenancy(propertyId: number = this.propertyId){

    this.router.navigate(['/property', propertyId, 'tenancy', 'add']);
    return;
    const initialState: any = {
      context: {
        childComponent: AddEditTenancyComponent
      },
      data: {
        someData: 'Hello, AddTenancyComponent!'
      },
      okBtnText: 'Save',
      cancelBtnText: 'Cancel',
      showHeaderClose: true,
      showOnlyCloseBtn: false,
      footerClass: 'custom-footer-class'
    }
  const modal = this.modalService.show(ModalDialogComponent,  AppModalConfig.generate(initialState));
  // if (modal.onHide) {
  //   modal.onHide.subscribe(
  //     whatIsThis => {
  //       ;
  //       // Implement navigation or other logic after modal close if necessary
  //     },
  //     error => {
  //       console.error(error); // This can be left here as it gives us info if any errors
  //       console.error(error);
  //     }
  //   );
  // }

}
}
