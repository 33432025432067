import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { AddressLookup } from '../../models/address-lookup';
import { Address } from '../../models/address';

@Injectable({
  providedIn: 'root'
})
export class PostcodeService {

  constructor(private _baseService: BaseApiService) {  }

  Lookup(postcode : string): Observable<AddressLookup> {
    const url = `/api/postcode/lookup?postcode=${postcode}`;
    return this._baseService.get<AddressLookup>(url);
  }

  Split(fullAddress : string): Observable<Address> {
    const url = `/api/postcode/split?fullAddress=${fullAddress}`;
    return this._baseService.get<Address>(url);
  }
}
