import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// Service exposing functions to retrieve the current Hub API settings.
@Injectable()
export class PulseApiSettingsService {

  constructor(
    // private userIdentityService: UserIdentityService
    ) { }

  getApiServiceBaseUrl = () => environment.apiBaseUri;

  // getAccessToken = () => this.userIdentityService.getAccessToken();
}
