import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { RegisterComponent } from './components/register/register.component';
import { CoreModule } from '@src/app/core/core.module';
import { SharedModule } from '@src/app/shared/shared.module';
import { PricingComponent } from './components/pricing/pricing.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ForgottonPasswordComponent } from './components/forgotton-password/forgotton-password.component';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  declarations: [
    RegisterComponent,
    PricingComponent,
    ContactUsComponent,
    VerifyEmailComponent,
    ForgottonPasswordComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    FormsModule,
    UiSwitchModule,
    AlertModule.forRoot(),
  ],
  exports: [
    RegisterComponent
  ]
})
export class AnonymousModule { }
