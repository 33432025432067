<div
  class="mb-3 row"
  [ngClass]="{ 'has-error-not-working': control.touched && errorMessage }"
>
  <label class="col-form-label col-sm-3 control-label">
    {{ label }}
    <span *ngIf="required" class="text-danger ">  *</span>
  </label>

  <div class="col-sm-9" [class.is-invalid]="shouldDisplayError()" [id]="elementId">
    <ng-content></ng-content>
      <div *ngIf="shouldDisplayError()" class="text-danger">
       {{ errorMessage }}
    </div>
    <!-- <div *ngIf="shouldDisplayError()">
      <div *ngIf="errorMessage" class="invalid-feedback-not-working text-danger ">
        {{ errorMessage }}
      </div>
    </div> -->

    <!-- <div *ngIf="validationErrors[name] && control?.touched" class="text-error">
      <i class="fa fa-warning"></i> {{ validationErrors[name] }}
    </div> -->
  </div>
</div>
