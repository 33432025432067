import { BaseAuditModel } from "./baseAuditModel";
import { LettingsPropertyLite } from "./lettingsPropertyLite";
export class TenancyLite extends BaseAuditModel {
  propertyId: number | null = 0;
  property: LettingsPropertyLite | null = null;
  dateStart: Date | null = null;
  dateEnd: Date | null= null;
  dateNextPayment: string | null = '';
  rentAmount: number | null = null;
  depositAmount: number | null = null;
  rentFrequency: string = '';
  renewalStatusId: number | null = 0;
  additionalRequirements: string = '';
  initialTermMonthsId: number = 6;
  leaseTypeId: number = 0;
  termTypeId: number = 0;
  statusId: number = 0;
  rentFrequencyId: number = 0;
}
