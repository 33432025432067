import { Component, Input } from '@angular/core';

@Component({
  selector: 'tlp-rent-and-bills',
  templateUrl: './rent-and-bills.component.html',
  styleUrls: ['./rent-and-bills.component.css']
})
export class RentAndBillsComponent {

  @Input()
  public isLandlord = false;
  isRentMenuCollapsed = true;

  
  public onMenuItemClick(): void {
    // do we need this ??
    if (window.innerWidth < 768) {
      // this.isCollapsed = true;
      // this.smallScreen = true;
      // this.toggleSidebar.emit();
    }
  }
}
