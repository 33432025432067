import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent {

  isYearly: boolean = true;
  prices = {
    free: { monthly: 0, yearly: 0 },
    starter: { monthly: 5, yearly: 50 },
    pro: { monthly: 10, yearly: 100 },
    enterprise: { monthly: 'POA', yearly: 'POA' }
  };

  togglePayment() {
    this.isYearly = !this.isYearly;
  }
}
