import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appLazyLoadImgSrc]'
})
export class ImageLazyLoadingDirective implements OnInit {
    @Input() appLazyLoadImgSrc!: string;
    @Input() defaultImage: string = '/assets/images/house-placeholder.jpg';
    @Output() loaded = new EventEmitter<void>();

    constructor(private elRef: ElementRef) {}

    ngOnInit(): void {
      const imgElement = this.elRef.nativeElement as HTMLImageElement;

      imgElement.src = this.defaultImage; // Set the default image initially
      imgElement.classList.add('loading'); // Add the loading class

      imgElement.onload = () => {
        imgElement.classList.remove('loading'); // Remove the loading class once the image is loaded
        this.loaded.emit();
      };

      imgElement.onerror = () => {
        imgElement.src = this.defaultImage;
        imgElement.classList.remove('loading'); // Remove the loading class if there is an error
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          // if (entry.isIntersecting ){//&& imgElement.src !== this.appLazyLoadImgSrc) {
          if (entry.isIntersecting && imgElement.src !== this.appLazyLoadImgSrc) {
            const imageToLoad = new Image();
            imageToLoad.src = this.appLazyLoadImgSrc;
            imageToLoad.onload = () => {
              imgElement.src = this.appLazyLoadImgSrc;
              imgElement.classList.remove('loading'); // Remove the loading class once the image is loaded
              imgElement.classList.remove('loading');
              this.loaded.emit();
            };
            imageToLoad.onerror = () => {
              imgElement.src = this.defaultImage;
              imgElement.classList.remove('loading'); // Remove the loading class if there is an error
            };
            imgElement.classList.remove('loading');
            observer.unobserve(imgElement);
          }
        });
      }, { threshold: 0.0 });

      observer.observe(imgElement);
    }
}

