import { ModalOptions } from 'ngx-bootstrap/modal';

/**
 * Extended version of the ModalOptions class from ngx-bootstrap. Used to match our modal configurations
 *
 * @export
 * @class AppModalConfig
 * @extends {ModalOptions}
 */
export class AppModalConfig extends ModalOptions {
    // initialState?: {
    //     context: {};
    // };

    /**
     * Gets the default modal configuration
     *
     * @private
     * @static
     * @returns {AppModalConfig}
     * @memberof AppModalConfig
     */
    private static getDefault(): AppModalConfig {
        return {
            animated: true,
            class: 'modal-dialog modal-lg',
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
                // context: {}
            }
        };
    }

    /**
     * Generates an modal config file
     * @param context - This is the data we want to assign and pass to the modal
     * @param  config - This is the config file, if not passed it will be passed as null
     * and use default config file.
     * @returns A new config file
     */
    public static generate(context: Object, config: Object | null = null): AppModalConfig {
      const newConfig = AppModalConfig.getDefault();
      Object.assign(newConfig, config);
      newConfig.initialState = {}; // Initialize initialState as an empty object
      if (context) {
        Object.assign(newConfig.initialState, context);
      }
      return newConfig;
    }
}
