import { FileStorageLocation } from "@core/enums/FileStorageLocation";
import { FileUploadArea } from "@core/enums/FileUploadArea";
import { BaseAuditModel } from "./baseAuditModel";

export class FileUpload extends BaseAuditModel {
  area: FileUploadArea = FileUploadArea.General;
  lookupKey: string = '';
  filePath: string = '';;
  contentType: string = '';;
  title: string = '';;
  description: string = '';;
  originalFilename: string = '';;
  isWordTemplate: string = '';;
  pulseFilename: string = '';;
  extension: string = '';;
  sortorder: number = 0;
  storageLocation: FileStorageLocation = FileStorageLocation.AWS;
  link: string = '';
  Files: File[] = [];

}

