import { Component } from '@angular/core';

@Component({
  selector: 'tlp-subscription-limit-reached',
  templateUrl: './subscription-limit-reached.component.html',
  styleUrls: ['./subscription-limit-reached.component.css']
})
export class SubscriptionLimitReachedComponent {

}
