<div class="container-fluid-disabled">

  <!-- Top header component -->
  <!-- <app-header (toggleSidebar)="toggleSidebar()"></app-header> -->
  <div class="row">
    <!-- Collapsible Sidebar Component -->
    <!-- Adjust the classes to control visibility based on the isSidebarCollapsed property -->

    <app-sidebar [isCollapsed]="isSidebarCollapsed" class="col-12 col-md-3 col-lg-2"></app-sidebar>

    <!-- Main content area -->
    <main class="col-12 col-md-9 col-lg-10 ms-sm-auto px-md-4 content p-3" id="main-content">

      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      <div class="row h-100">
        <router-outlet></router-outlet>
      </div>

    </main>
  </div>
</div>
