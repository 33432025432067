import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { AuthModule } from './core/authentication/auth.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from './layout/main/layout.main.module';
import { LoggedOutLayoutModule } from './layout/logged-out/logged-out-layout.main.module';
import { CoreModule } from '@core/core.module'; // Add this line
import { AnonymousModule } from '@landlord/features/anonymous/anonymous.module';

// 3rd party Components
import { NgSelectModule } from '@ng-select/ng-select';// Add this line
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery'
import { DragDropModule } from '@angular/cdk/drag-drop';

//ngx
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import {BsDatepickerModule,BsDatepickerConfig,} from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse'; // And this line
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingSpinnerService } from './shared/services/loading-spinner.service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FileUploadModule } from '@iplab/ngx-file-upload';

//datepicker
import {  BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale} from 'ngx-bootstrap/locale';
defineLocale('en-gb', enGbLocale);

// app components
import { ToastrModule } from 'ngx-toastr';
import { DropdownService } from './core/services/dropdown.service';

// Add error handling logging via Rollbar
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar/rollbar.module';
import { JwtModule } from '@auth0/angular-jwt';
import { AppCustomPreloadingStrategy } from './app-routing.preload-strategy';
import { LandlordGuard } from './core/guards/landlord.guard';
import { TenantGuard } from './core/guards/tenant.guard';
import { SubscriptionInterceptor } from './core/interceptor/subscription.interceptor';

export async function initializeApp(dropdownService: DropdownService) {
  if(DropdownService.isInitialised) return;
  return async () => await dropdownService.initialise();
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), // ToastrModule added with bottom-right position and preventDuplicates
    PhotoGalleryModule,
    FormsModule, // Add FormsModule here to use ngModel
    RouterModule, // Add RouterModule to the imports array
    FormsModule,
    RouterModule.forRoot([], {
      preloadingStrategy: AppCustomPreloadingStrategy
    }),
    AppRoutingModule,
    // NgbModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    LayoutModule,
    AuthModule,
    LoggedOutLayoutModule,
    CoreModule,
    NgSelectModule,
    FormsModule,
    TabsModule.forRoot(),
    // AlertModule,
    ModalModule.forRoot(),
    AnonymousModule,
    FileUploadModule,
    DragDropModule,
    JwtModule.forRoot({
      config: {
        // tokenGetter: tokenGetter,
        // allowedDomains: ["yourdomain.com"],
        // disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
    // NoopAnimationsModule, // or use NoopAnimationsModule
  ],
  // ... rest of the file remains unchanged ...
  exports: [

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubscriptionInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      deps: [DropdownService],
      multi: true
    },
    { provide: RollbarService, useFactory: rollbarFactory },
    AlertConfig,
    BsDatepickerConfig,
    BsDropdownConfig,
    LoadingSpinnerService,
    LandlordGuard,
    TenantGuard,
  ],
  // providers: [PropertyService],
  bootstrap: [AppComponent],
})


export class AppModule {

  constructor(
    private bsLocaleService: BsLocaleService,
  ){
    this.bsLocaleService.use('en-gb');//use the uk english locale for ngx-datepicker

  }


}
