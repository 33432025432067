<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" >

  <file-upload formControlName="files" [multiple]="multiple" [animation]="animation"></file-upload>

  <!-- <control-wrapper name="files" label="File Upload" [control]="form.controls['files']">
    <input type="file" formControlName="files" multiple class="form-control"
      [class.is-invalid]="isControlInvalid('files')" (change)="onFileChange($event)" >
  </control-wrapper> -->

  <control-wrapper name="title" label="Document title" [control]="form.controls['title']" [validationErrors]="getValidationMessages('title')" *ngIf="fileTitleVisible">
    <input type="text" formControlName="title" class="form-control"
      [class.is-invalid]="isControlInvalid('title')">
  </control-wrapper>


  <control-wrapper name="description" label="Description" [control]="form.controls['description']" [validationErrors]="getValidationMessages('description')" *ngIf="fileDescriptionVisible">
    <textarea type="text" formControlName="description" class="form-control"
      [class.is-invalid]="isControlInvalid('description')" rows="10"></textarea>
  </control-wrapper>
</form>
