import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppDialogContext } from '../../models/app-modal-context';
import { ModalDialogComponent } from '../modal/modal-dialog/modal-dialog.component';
import { AddEditTenancyComponent } from '@landlord/features/tenancy/components/add-edit-tenancy/add-edit-tenancy.component';
import { FileUploadArea } from '@src/app/core/enums/FileUploadArea';

@Component({
  selector: 'app-add-image-button',
  templateUrl: './add-image-button.component.html',
  styleUrls: ['./add-image-button.component.css']
})
export class AddImageButtonComponent {
  @Input()
  imageKey: string = '';
  @Input()
  targetImageArea: FileUploadArea = FileUploadArea.General;
  constructor(
    public modalService: BsModalService,
  ){}

  addNewImage(){
    const context: AppDialogContext = AppDialogContext.generate({ childComponent: AddEditTenancyComponent });
    // context.childComponent = AddTenancyComponent;
    // const modalOptions: ModalOptions = { initialState: AppDialogContext.generate({ childComponent: AddTenancyComponent }) }
    const modal = this.modalService.show(ModalDialogComponent,  context );
    // Here the component subscribes to when the modal is closed
    if (modal.onHide) {
      modal.onHide.subscribe(
        whatIsThis => {
          // this.router.navigate([`../somewhere/${id}`], { relativeTo: this.route });
        },
        error => {
          console.error(error); // This can be left here as it gives us info if any errors
        }
      );
    }

  }
}
