import { BaseAuditModel } from "./baseAuditModel";

export class Address extends BaseAuditModel {
  number: string = '';
  street: string = '';
  buildingName: string = '';
  flatNo: string = '';
  city: string = '';
  county: string = '';
  postCode: string = '';
  country: string = '';
  latitude: string = '';
  longitude: string = '';
  fullAddress: string = '';

  public isEmpty(): boolean {
    return this.number === '' && this.street === '' && this.buildingName === '' && this.flatNo === '' && this.city === '' && this.county === '' && this.postCode === '' && this.country === '';
  }
}
