import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to control the visibility of a global loading spinner.
 *
 * This service provides methods to show or hide the loading spinner,
 * which can be used across different components to indicate the
 * loading state of the application or specific data-fetching processes.
 */
@Injectable()
export class LoadingSpinnerService {
  private loadingSource = new BehaviorSubject<Boolean>(false);
  loading = this.loadingSource.asObservable();

  constructor() {}

  // Show the loading spinner.
  show(): void {
    this.loadingSource.next(true);
  }

  // Hide the loading spinner.
  hide(): void {
    this.loadingSource.next(false);
  }
}
