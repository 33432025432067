import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormComponent } from '@src/app/shared/components/form/form/form.component';
import { UserService } from '@src/app/core/services/user.service';
import { ForgotPasswordViewModel } from '@src/app/models/users/ForgotPassword';

@Component({
  selector: 'tlp-forgotton-password',
  templateUrl: './forgotton-password.component.html',
  styleUrls: ['./forgotton-password.component.css']
})
export class ForgottonPasswordComponent extends FormComponent {
  form: FormGroup = new FormGroup({});
  model: ForgotPasswordViewModel = new ForgotPasswordViewModel();
  message: string = '';

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.generateForm();
    super.ngOnInit();
  }

  public generateForm(): void {
    this.form = this.fb.group({
      email: [this.model.email, [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (!this.validateForm()) {
      this.getErrorMessages().map((message) => {
        this.toastr.error(message);
      });
      return;
    }
    this.model = { ...this.model, ...this.form.value };
    this.userService.sendforgotPasswordRequest(this.model).subscribe({
      next: () => {
        this.message = 'Password reset link has been sent to your email.';
        this.toastr.success(this.message);
      },
      error: (error) => {
        this.message = 'An error occurred. Please try again.';
        this.toastr.error(this.message);
        console.error('Error requesting password reset:', error);
      },
    });
  }

  public validationMessages = {
    email: {
      required: 'Email is required',
      email: 'Email must be a valid email address',
    },
  };


}
