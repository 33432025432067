import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable, catchError, map, of } from 'rxjs';
import { User } from '@src/app/models/user';
import { ForgotPasswordViewModel } from '@src/app/models/users/ForgotPassword';
import { ResetPasswordViewModel } from '@src/app/models/users/ResetPasswordViewModel';
import { UserProfile } from '@src/app/models/userProfile';
import { LoginResponse } from '../authentication/models/LoginResponse';
import { UserIdentityService } from '../authentication/services';
import { APIResponse } from '@src/app/models/APIResponse';


// Class for querying and updating the logged in user data.
// Use this service to interact with the user data and not the low level user identity service.
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _baseService: BaseApiService,
    // private userService: UserService,
    private authService: UserIdentityService,
  ) {  }

  get(id: string): Observable<UserProfile> {
    const url = `/api/UserProfile/${id}`;
    return this._baseService.get<UserProfile>(url);
  }

  getCurrentUserProfile(): Observable<UserProfile> {
    let currentUser = this.authService.currentUser();
    if (currentUser) {
      return this.get(currentUser.userId);
    } else {
      return of(new Error('Cannot get the current user') as any);
    }
  }

  disableUser(id: string): Observable<void> {
    const url = `/api/UserProfile/${id}/disable-user`;
    return this._baseService.post<void>(url, {});
  }

  enableUser(id: string): Observable<void> {
    const url = `/api/UserProfile/${id}/enable-user`;
    return this._baseService.post<void>(url, {});
  }

  // checks it he password meets the regex standard
  validatePasswordStrength(password: string): boolean {
    // this regex checks if the password has:
    // at least 8 characters,
    // one uppercase letter,
    // one number
    // one special character
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  }

  getAll(): Observable<UserProfile[]> {
    const url = `/api/UserProfile`;
    return this._baseService.get<UserProfile[]>(url);
  }

  upsert(company: UserProfile): Observable<number> {
    const url = `/api/UserProfile`;
    return this._baseService.post<number>(url, company);
  }

  sendforgotPasswordRequest(model: ForgotPasswordViewModel): Observable<void> {
    const returnUrl = `${window.location.origin}/reset-password`;
    const url = `/api/account/send-password-reset-link?returnUrl=${returnUrl}`;
    return this._baseService.post<void>(url, model);
  }

  resetPassword(model: ResetPasswordViewModel): Observable<void> {
    const returnUrl = `${window.location.origin}/reset-password`;
    const url = `/api/account/reset-password?returnUrl=${returnUrl}`;
    return this._baseService.post<void>(url, model);
  }

  login(email: string, password: string): Observable<APIResponse<LoginResponse>> {
    const url = `/api/account/login`;
    return this._baseService.post<APIResponse<LoginResponse>>(url, { email, password }).pipe(
      map((loginResponse) => {
        if(loginResponse.isSuccess)
          this.authService.createUserFromLoginResponse(loginResponse.data as LoginResponse);
        return loginResponse

      }),
      catchError((error) => {
        console.error('Login failed:', error);
        const errorResponse = new APIResponse<LoginResponse>({} as Error);
      errorResponse.isSuccess = false;
      errorResponse.hasErrors = true;
      errorResponse.errors = [error.message];
      return of(errorResponse);
      })
    );
  }

    // Check if the user is in a role.
    isInRole( role: string): boolean {
      return this.authService.isInRole(role);
    }

  // Check if the user is in any of the roles.
  hasRole(roles: string[]): boolean {
    return roles.some(role => this.authService.hasRole(roles));
  }
}
