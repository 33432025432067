import { OnDestroy, OnInit, Directive, Component } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { map, mapValues } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { IFormComponent } from './Iform.component';
import { AppBaseComponent } from '@src/app/shared/components/app-base/app-base.component';

// @Directive({
//   selector: '[appFormComponent]', // Add the selector here
// })
@Component({
  selector: 'appFormComponent',
  template: '',
})
@AutoUnsubscribe()
// Abstract class implementing common functionality used in the app's forms.
export abstract class FormComponent extends AppBaseComponent
  implements OnInit, OnDestroy, IFormComponent
{
  // The form which we are working with in this modal.
  abstract form: UntypedFormGroup;
  abstract onSubmit(): void;

  // abstract list of messages to display on the front end
  abstract validationMessages: { [key: string]: { [key: string]: string } };

  // Regex for use in forms
  static readonly DIGITS_ONLY = /^\d+(\.\d{1,2})?$/;

  // This method checks whether the specified property exists on the validationErrors object
  // If it does, it returns the value of that property. Otherwise, it returns an empty string.
  getValidationMessages(controlName: string): { [key: string]: string } {
    // Check whether the specified control exists in the validationErrors object
    if (this.validationMessages && this.validationMessages[controlName]) {
      // console.log('validationMessages for ' + controlName, this.validationMessages[controlName]);
      // Return the error message for the specified control
      return this.validationMessages[controlName];
    } else {
      // Return an empty string if the specified control does not exist in the validationErrors object
      return { [controlName]: 'Field is required' };
      return {} as { [key: string]: string }; //default with empty message
    }
  }

  // Whether the form data is currently being saved/submitted.
  saveInProgress = false;
  serverError = '';

  // Dictionary of the current validation errors which will be displayed in the UI - eg:
  // {
  //   'firstName': ''
  //   'lastName': ''
  // }
  validationErrors: { [key: string]: string } = {};

  // Child component should call this manually after setting up the form in its own ngOnInit.
  ngOnInit(): void {
    // Generate form errors dictionary from the validation messages.
    this.validationErrors = mapValues(this.validationMessages, () => '');

    map(this.form.controls, (control, fieldName) => {
      // Update the validation errors when any control's value changes.
      control.valueChanges.subscribe(() => {
        this.updateValidationErrors(fieldName);
      });
      // Update the validation errors on initialisation.
      this.updateValidationErrors(fieldName);
    });
  }

  // Updates the validation errors which will be shown in the UI - note that we do not reason about whether the field is dirty/touched here.
  updateValidationErrors(field: string): void {
    // Clear previous error message (and potentially remove the is-invalid class).
    this.validationErrors[field] = '';
    const control = this.form.get(field);
    if (control && !control.valid) {
      const messages = this.validationMessages[field];
      // Show only the first error for this field.
      if (messages) {
        const firstError = Object.keys(messages).find(
          (key) => control.errors && control.errors[key]
        );
        if (firstError) {
          this.validationErrors[field] = messages[firstError];
        }
      }
    }
  }

  // Returns an array of error messages to be displayed in the UI.
  getErrorMessages() {
    let errorMessages: string[] = [];
    for (const key in this.validationErrors) {
      if (this.validationErrors.hasOwnProperty(key)) {
        const value = this.validationErrors[key];
        if (value) {
          errorMessages.push(value);
        }
      }
    }
    return errorMessages;
  }

  // Updates the validation errors, then returns true if there are no invalid controls, false otherwise.
  validateForm(): boolean {
    // Set any invalid controls to touched and show any form errors.
    const invalidControls = map(this.form.controls).filter(
      (control) => !control.valid
    );

    // Mark all controls as touched to ensure errors will be shown in the UI.
    map(this.form.controls, (control) => {
      this.markControlTouched(control);
    });

    Object.keys(this.validationErrors).map((field) => {
      this.updateValidationErrors(field);
    });
    return !invalidControls.length;
  }



    // Converts the date received from the Api to dd/MM/yyyy format
    convertDateFromApiFormat(date: string | Date | null): string | null {
      if(date === null)
        return null;
      if(typeof(date) === 'string'){
        const dateParts = date.split('T')[0]?.split('-');
        date = new Date(`${dateParts[0]}-${dateParts[1].padStart(2, '0')}-${dateParts[2].padStart(2, '0')}`);
      }
            date = new Date(date);
      const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      return formattedDate;
    }

    // Converts the date to the format required by the Api
    convertDateToApiFormat(date: string | Date | null): string {
      if(date === null)
        return '';
    if(typeof(date) === 'string'){
      const [day, month, year] = date.split('/');
      date = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${this.pad(month)}-${this.pad(day)}`;
  }

  // // Recursively find a parameter in the route hierarchy.
  // findParameter(route: ActivatedRoute, paramName: string): string | null {
  //   if (route.snapshot.paramMap.has(paramName)) {
  //     return route.snapshot.paramMap.get(paramName);
  //   } else if (route.parent) {
  //     return this.findParameter(route.parent, paramName);
  //   } else {
  //     return null;
  //   }
  // }

  private pad(number: number): string {
    return (number < 10 ? '0' : '') + number;
  }

  markControlTouched(control: AbstractControl): void {
    control.markAsTouched();
    if (control instanceof UntypedFormArray) {
      (control as UntypedFormArray).controls.map((c) =>
        this.markControlTouched(c)
      );
    }
    if (control instanceof UntypedFormGroup) {
      map((control as UntypedFormGroup).controls, (c) =>
        this.markControlTouched(c)
      );
    }
  }

  // Helper method to determine if a form control is invalid and has been either dirtied or touched
  isControlInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control
      ? control.invalid && (control.dirty || control.touched)
      : false;
  }

  serverErrorCallback = (response: HttpErrorResponse): void => {
    this.serverError =
      response.error.Message ||
      'An error occurred while processing your request. Please try again later.';
    this.saveInProgress = false;
    throw response.error.Message;
  };

  // Don't allow the user to dismiss the modal while saving.
  beforeDismiss(): boolean {
    return this.saveInProgress;
  }

  // Don't allow the user to close the modal while saving.
  beforeClose(): boolean {
    return this.saveInProgress;
  }

  ngOnDestroy(): void {}
}
