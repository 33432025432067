<div *ngIf="verficationEmailSent" class="row justify-content-center mt-4">
  <div class="col-lg-8 mx-auto">
    <h1 class="text-center">A verification email has now been sent.</h1>
    <h4 class="text-center">Please check your email and click on the link to verify your email address</h4>
  </div>
</div>
<div *ngIf="!verficationEmailSent">
  <div class=" justify-content-center text-center  mt-4 row">
    <h2 class="col-12 p-4">
      <strong>Register now</strong>
    </h2>
    <h4 class="col-12">
      <strong>Create an account to get access to all our great property features</strong>
    </h4>
    <br>
  </div>

  <div class="row justify-content-center mt-4">
    <div class="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
      <form [formGroup]="form" novalidate="novalidate">
        <div class="validation-summary-valid" data-valmsg-summary="true">
          <ul>
            <li style="display:none"></li>
          </ul>
        </div>
        <div class="container">
          <div class="row align-center center-block justify-content-center text-center  mt-4">
            <div class="col-12 col-m-md-8 row">
              <input class="form-control" value="Owner" type="hidden" id="Role" name="Role">

              <control-wrapper name="firstName" label="First name" [control]="form.controls['firstName']">
                <input class="form-control" type="text" formControlName="firstName"
                  [class.is-invalid]="isControlInvalid('firstName')">
                <div *ngIf="isControlInvalid('firstName')" class="invalid-feedback">
                  {{ validationErrors['firstName'] }}
                </div>
              </control-wrapper>

              <control-wrapper name="lastName" label="Last name" [control]="form.controls['lastName']"
                [validationErrors]="validationMessages['lastName']">
                <input class="form-control" type="text" formControlName="lastName"
                  [class.is-invalid]="isControlInvalid('lastName')">
              </control-wrapper>

              <control-wrapper name="email" label="Email address" [control]="form.controls['email']">
                <input class="form-control" type="email" formControlName="email"
                  [class.is-invalid]="isControlInvalid('email')">
                <app-validation-error [control]="form.controls['email']"
                  [validationErrors]="getValidationMessages('email')"></app-validation-error>
              </control-wrapper>

              <div class="row align-center center-block justify-content-center text-center  mt-4">
                <button type="submit" (click)="onSubmit()" class="btn btn-primary col-8 col-md-4">Register</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
