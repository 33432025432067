import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserIdentityService } from '@src/app/core/authentication/services';
import { User } from '@core/authentication/models';
import { UserService } from '@src/app/core/services/user.service';
import { UserProfile } from '@src/app/models/userProfile';
import { AppSidebarComponent } from '../app-sidebar/app-sidebar.component';


@Component({
  selector: 'tlp-landlord-sidebar',
  templateUrl: './landlord-sidebar.component.html',
  styleUrls: ['./landlord-sidebar.component.css']
})
export class LandlordSidebarComponent extends AppSidebarComponent implements OnInit {
  isMaintenanceMenuCollapsed = true;
  isRentMenuCollapsed = true;


}

