export enum LookupTableLookup{
  None = 0,
  PropertyAgencyCommissionType,
  OutsideSpace,
  HeatingTypes,
  PropertyCouncilTaxBand,
  PropertyManagementType,
  PropertyDepartment,
  ApplicantTitle,
  LandlordStatus,
  DiaryEventType,
  DiaryDuration,
  ExternalPortal,
  TenancyRewnewalStatus,
  ApplicantTermRequired,
  ApplicantStatus,
  NewUserType,
  TenancyDepositScheme,
  ApplicantPropertyType,
  ApplicantPropertyStyle,
  TenancyLodgedBy,
  ApplicantPets,
  TenancyReceivedBy,
  ApplicantAddressResidentialStatus,
  ApplicantDepartment,
  ApplicantEnquiryMethod,
  ApplicantEmploymentStatus,
  ApplicantFurniture,
  ApplicantEnquirySource,
  PropertyCertificateStatus,
  PropertyInspectionStatus,
  PropertyCertificateEPCRating,
  LandlordEnquirySource,
  TenancyTermType,
  PropertyCertificateType,
  TenancyStatus,
  TenancyRentFrequency,
  TenancyRenewalStatus,
  LocalAuthority,
  TenancyLeaseType,
  TenancyInitialTermMonths,
  PropertyType,
  PropertyTenure,
  PropertyStatusLevel,
  PropertyStyle,
  PropertyStatus,
  PropertyRentalContract,
  PropertyFurniture,
  Parking,
  PropertyPriceType,
  EmailTemplateType,

}
