import { Directive, HostListener, Input, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appClickableRow]'
})
export class ClickableRowDirective {
  @Input() appClickableRow: Array<any> = [];

  constructor(private router: Router, private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
  }

  @HostListener('click')
  onClick() {
    if (this.appClickableRow) {
      console.log(this.appClickableRow);
      this.router.navigate(this.appClickableRow);
    }
  }
}
