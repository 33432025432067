import { Component, OnInit } from '@angular/core';
import { RegisterNewAgency } from '@src/app/models/register-new-agency';
import {
  UntypedFormGroup,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { FormComponent } from '@src/app/shared/components/form/form/form.component';
import { AccountService } from '@src/app/core/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { NewUserType } from '@src/app/core/enums/NewUserType';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent extends FormComponent implements OnInit {
  form: UntypedFormGroup = new FormGroup({});
  newAgency: RegisterNewAgency = {} as RegisterNewAgency;
  verficationEmailSent = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toastr: ToastrService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.generateForm();
    super.ngOnInit();
  }

  // ... rest of the component
  public generateForm(): void {
    // this.form = this.fb.group(this.newAgency);
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      // password: ['', [Validators.required]],
      // confirmPassword: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (!this.validateForm()) {
      console.error('Form is invalid');
      return;
    }

    // Update the agency with the current form values
    this.newAgency = { ...this.newAgency, ...this.form.value };
    this.newAgency.type = NewUserType.Owner;  // assign owner/landlord type to the new user
    this.accountService.registerNewUser(this.newAgency).subscribe({
      next: (result: boolean) => {
        console.log('success result', result);
        this.verficationEmailSent = true;
      },
      error: (error) => {
        this.toastr.error(error, 'An error has occured:');
      },
    });
  }

  override validationMessages: { [key: string]: { [key: string]: string } } = {
    firstName: {
      required: 'First name is required',
    },
    lastName: {
      required: 'Last name is required',
    },
    email: {
      required: 'Email is required',
      email: 'Email must be a valid email address',
    },
    // password: {
    //   required: 'Password is required',
    // },
    // confirmPassword: {
    //   required: 'Confirm password is required',
    // },
    // ... other validation messages ...
  };
}
