import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DropdownService } from '@core/services/dropdown.service';
import { Observable, combineLatest, of } from 'rxjs';
import { Lookup } from '@src/app/models/lookup';
import { FormComponent } from '@src/app/shared/components/form/form/form.component';
import { PropertyDetailsDropdown } from '@src/app/core/types/property-details-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { LettingPropertyService } from '@src/app/core/services/letting.property.service';
import { SettingsService } from '@src/app/core/services/settings.service';
import { TenancyLite } from '@src/app/models/tenancyLite';
import { LettingTenancyService } from '@src/app/core/services/letting.tenancy.service';
import { TenancyDetailsDropdown } from '@src/app/core/types/tenancy-details-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { addDays, addMonths, addYears, subDays } from 'date-fns';

@Component({
  selector: 'app-add-tenancy',
  templateUrl: './add-edit-tenancy.component.html',
  styleUrls: ['./add-edit-tenancy.component.css'],
})
export class AddEditTenancyComponent extends FormComponent implements OnInit {

  tenancyId: number = 0;
  propertyId: number = 0;
  form: UntypedFormGroup = new FormGroup({});
  dropdowns: TenancyDetailsDropdown = {} as TenancyDetailsDropdown;

  selectEmptyOption: Lookup = {} as Lookup;
  tenancy: TenancyLite = new TenancyLite();

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private tenancyService: LettingTenancyService,
    private route: ActivatedRoute,
    private dropdownService: DropdownService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.route.parent?.paramMap.subscribe((paramMap) => {
      this.tenancyId = +(paramMap.get('tenancyId') ?? 0);
    });

    // this.route.parent?.parent?.parent?.paramMap.subscribe((paramMap) => {
    //   this.propertyId = +(paramMap.get('propertyId') ?? 0);
    // });
    if(this.tenancyId == 0){
      this.propertyId = Number(this.route.parent?.parent?.parent?.snapshot.paramMap.get('propertyId') ?? '');
    } else {
      this.propertyId = Number(this.route.parent?.parent?.parent?.parent?.snapshot.paramMap.get('propertyId') ?? '');
    }

    this.selectEmptyOption = {
      text: this.settingsService.get().selectEmptyOption,
      value: '0',
    };
    this.generateForm();

    combineLatest([
      this.dropdownService.getTenancyDropdowns(),
      this.tenancyId > 0 ? this.tenancyService.get(this.tenancyId) : of(null),
    ]).subscribe(([dropdowns, tenancy]) => {
      this.dropdowns = dropdowns;
      if (tenancy) {
        this.tenancy = tenancy;
      }
      this.generateForm();


      this.form.get('initialTermMonthsId')?.valueChanges.subscribe(() => {
        this.form.get('dateEnd')?.setValue(this.calculateEndDate());
      });
      this.form.get('dateStart')?.valueChanges.subscribe(() => {
        this.form.get('dateEnd')?.setValue(this.calculateEndDate());
      });
      if(this.tenancyId == 0)
        this.form.get('dateEnd')?.setValue(this.calculateEndDate());
    });
    super.ngOnInit();
  }

  public generateForm(): void {

    this.form = this.fb.group({
      id: [this.tenancy.id],
      propertyId: [this.propertyId],
      initialTermMonthsId: [this.tenancy.initialTermMonthsId ?? 6, Validators.required],
      leaseTypeId: [this.tenancy.leaseTypeId, Validators.required],
      termTypeId: [this.tenancy.termTypeId, Validators.required],
      statusId: [this.tenancy.statusId],
      rentFrequencyId: [this.tenancy.rentFrequencyId],
      dateStart: [super.convertDateFromApiFormat(this.tenancy.dateStart) ?? new Date(), Validators.required],
      dateEnd: [super.convertDateFromApiFormat(this.tenancy.dateEnd), Validators.required],
      rentAmount: [this.tenancy.rentAmount, [Validators.required, Validators.pattern(FormComponent.DIGITS_ONLY)]],
      depositAmount: [this.tenancy.depositAmount, [Validators.required, Validators.pattern(FormComponent.DIGITS_ONLY)]],
      additionalRequirements: [this.tenancy.additionalRequirements]
    });
  }


  // Calculates the end date based on the start date and inital term
  private calculateEndDate(): Date {
    const initialTermMonthsId = this.form.get('initialTermMonthsId')?.value.toString();
    const startDate = this.form.get('dateStart')?.value;
    let endDate = new Date(startDate);
    if(!startDate || !initialTermMonthsId)
      return new Date();

    let monthsToAdd = initialTermMonthsId as number;
    endDate = subDays(addMonths(new Date(startDate), initialTermMonthsId), 1);
    return endDate;
  }

  onSubmit() {
    if (!this.validateForm()) {
      let allErrors = this.getErrorMessages() ?? [];

      this.getErrorMessages().map((message) => {
        this.toastr.error(message);
      });
      return;
    }
    // Update the propertyDetails with the current form values
    let dateStart = this.form.get('dateStart')?.value && this.convertDateToApiFormat(this.form.get('dateStart')?.value);
    let dateEnd = this.form.get('dateEnd')?.value && this.convertDateToApiFormat(this.form.get('dateEnd')?.value);

    this.tenancy = { ...this.tenancy, ...this.form.value , dateStart, dateEnd };
    this.tenancyService.upsert(this.tenancy).subscribe({
      next: (id: number) => {
        this.tenancy.id = id;
        this.toastr.success('Tenancy saved successfully');
         // Now redirect to the certificate list view
         setTimeout(() => {
          this.router.navigate([`/property/${this.propertyId}/tenancy/${this.tenancy.id}`]);
          }, 2000);
      },
      error: (error) => {
        console.error('Error updating the tenancy details:', error);
      },
    });
  }


  public validationMessages = {
    initialTermMonthsId: {
      required: 'The initial term is required',
    },
    leaseTypeId: {
      required: 'The lease type is required',
    },
    termTypeId: {
      required: 'The term type is required',
    },
    dateStart: {
      required: 'Start Date is required',
    },
    dateEnd: {
      required: 'End Date is required',
    },
    rentAmount: {
      required: 'Rent amount is required',
      pattern: 'Rent amount must be a valid number',
    },
    depositAmount: {
      required: 'Deposit amount is required',
      pattern: 'Deposit amount must be a valid number',
    },
  };
}
