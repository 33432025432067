
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from '@services/loading-spinner.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {
  isLoading: Boolean = false;
  private loadingSub: Subscription = new Subscription();
  isSidebarCollapsed: boolean = window.innerWidth < 768; // Default to collapsed on mobile
  selectedAddressId: number | null = null;

  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loadingSub = this.loadingSpinnerService.loading.subscribe(
      (loading) => {
        this.isLoading = loading;
        this.changeDetectorRef.detectChanges();
      }
    );
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    this.isSidebarCollapsed = window.innerWidth < 768;
  }

  ngOnDestroy() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }

  onAddressIdChanged(newAddressId: number): void {
    this.selectedAddressId = newAddressId;
    // Additional logic to handle the new address ID can be added here
  }
}
