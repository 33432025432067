import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tlp-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit {
  searchQuery: string = '';
  showSearchBox: boolean = true;
  private dragOffsetX: number = 0;
  private dragOffsetY: number = 0;

  constructor(
    private renderer: Renderer2,
    private router: Router,
  ) {
  }

  @ViewChild('searchInput') searchInput!: ElementRef;

  ngOnInit() {
    const savedPosition = localStorage.getItem('globalSearchButtonPosition');
    if (savedPosition) {
      const { top, left } = JSON.parse(savedPosition);
      const searchButton = document.getElementById('global-search-button');
      if (searchButton) {
        searchButton.style.top = `${top}px`;
        searchButton.style.left = `${left}px`;
      }
    }
  }

toggleSearchBox() {
  this.showSearchBox = !this.showSearchBox;
  const searchBox = document.getElementById('global-search-box');
  const searchButton = document.getElementById('global-search-button');
  if (!searchBox || !searchButton) return;

  if (this.showSearchBox) {
    const buttonRect = searchButton.getBoundingClientRect();
    searchBox.style.top = `${buttonRect.top + 25}px`;
    searchBox.style.left = `${buttonRect.right + 15}px`; // Adjust as needed
    searchBox.style.height = '4em'; // Ensure consistent height
    searchBox.classList.add('show');

    // Ensure the search box does not go off the right side of the screen
    const searchBoxRect = searchBox.getBoundingClientRect();
    if (searchBoxRect.right > window.innerWidth) {
      searchBox.style.left = `${window.innerWidth - searchBoxRect.width - 10}px`; // 10px padding from the edge
    }

    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);
  } else {
    searchBox.classList.remove('show');
  }
}

performSearch() {
  if (this.searchQuery.trim()) {
    this.router.navigate(['/global-search'], { queryParams: { q: this.searchQuery } });
    this.toggleSearchBox(); // Hide the search box after performing the search
    this.searchQuery = '';
  }
}

onKeydown(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    this.performSearch();
  }
}

onDragStart(event: DragEvent) {
  const target = event.target as HTMLElement;
  this.dragOffsetX = event.clientX - target.getBoundingClientRect().left;
  this.dragOffsetY = event.clientY - target.getBoundingClientRect().top;
}

onDragEnd(event: DragEvent) {
  const searchButton = event.target as HTMLElement;
  const top = event.clientY - this.dragOffsetY;
  const left = event.clientX - this.dragOffsetX;
  searchButton.style.top = `${top}px`;
  searchButton.style.left = `${left}px`;

  // Save position to local storage
  localStorage.setItem('globalSearchButtonPosition', JSON.stringify({ top, left }));
}
}
