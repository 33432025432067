import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'control-wrapper',
  templateUrl: './control-wrapper.component.html',
  styleUrls: ['./control-wrapper.component.css']
})
export class ControlWrapperComponent implements OnInit {
  private _elementId: string = null!;
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() validationErrors: { [key: string]: string } | undefined = {};
  @Input() tooltipText?: string;
  @Input() tooltipPlacement?: string;

  @Output() invalidChanged = new EventEmitter();

  required: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // We run the validator against a form control with an empty value and check if the required attribute exists on the result :)
    if (this.control?.validator && this.control?.validator(new UntypedFormControl(''))) {
      const errors = this.control.validator(new UntypedFormControl('')) || {};
      this.required = Object.keys(errors)?.includes('required');
    } else {
      this.required = false;
    }
    this.listenToStatusChanges();
  }

  get errorMessage(): string | undefined {
    const control = this.control;
    if (control) {
      const errors = control.errors;
      if (errors) {
        const firstKey = Object.keys(errors)[0];
        return this.validationErrors ? this.validationErrors[firstKey] : undefined;
      }
    }
    return undefined;
  }

  private listenToStatusChanges(): void {
    if(this.control === null){
      console.error('Control is null - ' + this.name);
      return
    }
    this.control?.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.addInvalidClasses();
      } else {
        this.removeInvalidClasses();
      }
    });
  }

  private removeInvalidClasses() {
    // Remove the 'is-invalid' class from the content within <ng-content>
    const el = document.getElementById(`${this.elementId}`);
    if (!el) return;
    const children = el.querySelectorAll('*');
    children.forEach((child) => {
      child.classList.remove('is-invalid');
    });
    el.classList.remove('is-invalid');
  }

  private addInvalidClasses() {
    // Add the 'is-invalid' class to the content within <ng-content>
    const el = document.getElementById(`${this.elementId}`);
    if (!el) return;
    const children = el.querySelectorAll('*');
    children.forEach((child) => {
      child.classList.add('is-invalid');
    });
    el.classList.add('is-invalid');
  }

  public get elementId() {
    if (!this._elementId) {
      this._elementId = Math.random().toString(36).substr(2) + '-wrapper';
    }
    return this._elementId;
  }
  // private get elementId() {
  //   // Generate a unique ID for each instance of the wrapper component
  //   return `${Math.random().toString(36).substr(2)}-wrapper`;
  // }

  shouldDisplayError(): boolean {
    const control = this.control;
    return this.control && control.invalid && (control.dirty || control.touched);
  }

  getErrorKeys(errors: ValidationErrors | null): string[] {
    return errors ? Object.keys(errors) : [];
  }

  // Object: any;
  // get errorMessage(): string | undefined {
  //   const control = this.control;
  //   if (control) {
  //     const errors = control.errors;
  //     if (errors) {
  //       const firstKey = Object.keys(errors)[0];
  //       return this.validationErrors ? this.validationErrors[firstKey] : undefined;
  //     }
  //   }
  //   return undefined;
  // }
}

