<ul class="nav flex-column" accordion>
  <li class="nav-item">
    <!-- parent pages-->
    <div class="nav-link">
      <span class="nav-link-icon"><span class="fa fa-home"></span></span><span class="nav-link-text ps-2">Tenant</span>
    </div>
  </li>
  <li class="nav-item ms-3">
    <a class="nav-link" routerLink="/tenant/tenancy" click="onMenuItemClick()">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-list"></span></span><span class="nav-link-text ps-2">My
          Tenancies</span>
      </div>
    </a>
  </li>
</ul>

<!-- {{user?.role === 'Tenant' ? '' : ''}} -->