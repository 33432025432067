  export enum ErrorDetailCode {
      /**
       * A user with this email already exists
       * ErrorStatusCode = BadRequest
       */
      UserWithThisEmailAlreadyExists = 400001,

      /**
       * File exists already
       * ErrorStatusCode = Unauthorized
       */
      MissingAuthenticationHeaders = 4014001,

      /**
       * The token is invalid
       * ErrorStatusCode = Unauthorized
       */
      TokenInvalid = 4014002,

      /**
       * The user is unknown
       * ErrorStatusCode = Unauthorized
       */
      UnknownUser = 4014003,

      /**
       * The token has expired
       * ErrorStatusCode = Unauthorized
       */
      TokenExpired = 4014004,

      /**
       * The token has expired
       * ErrorStatusCode = Unauthorized
       */
      UnknownLoginError = 4014010,

      /**
       * The image file is not found
       * ErrorStatusCode = NotFound
       */
      UserHasNoSubscription = 4034001,

      /**
       * The image file is not found
       * ErrorStatusCode = NotFound
       */
      ImageFileNotFound = 4044001,

      
        /// <summary>
        /// The user has reached the subscription limit
        /// ErrorStatusCode = Forbidden = 403
        /// </summary>
        SubscriptionLimitReached = 4034002,

      /**
       * An unhandled / unknown error has happened in the server.
       * ErrorStatusCode - InternalServerError
       */
      UnHandledOrUnknownError = 500001,

      /**
       * Equivalent to HTTP status 408. RequestTimeout indicates that the client did
       * not send a request within the time the server was expecting the request.
       */
      Timeout = 408001,

      /**
       * Exception type other than the above listed types
       */
      Other = 0
  }
