// Purpose: Guard to check if user is a tenant.

import { CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantGuard implements CanActivate {
  constructor(private authService: UserService) {}
  canActivate(): boolean {
    // Check if user is a tenant
    return this.authService.hasRole(['tenant']);
  }
}
