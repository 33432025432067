import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { TenancyLite } from '../../models/tenancyLite';
import { Observable, from, map } from 'rxjs';
import { Tenancy } from '../../models/tenancy';
import { TenancyProperty } from '@src/app/models/tenancyProperty';
import { Tenant } from '@src/app/models/tenant';

@Injectable({
  providedIn: 'root',
})
export class LettingTenancyService {
  constructor(private _baseService: BaseApiService) {}

  get(id: number): Observable<TenancyLite> {
    const url = `/api/lettings/tenancy/${id}`;
    return this._baseService.get<TenancyLite>(url);
  }
  getDetails(id: number): Observable<Tenancy> {
    const url = `/api/lettings/tenancy/${id}/details`;
    return this._baseService.get<Tenancy>(url);
  }
  getAll(): Observable<TenancyLite[]> {
    const url = '/api/lettings/tenancy';
    return this._baseService.get<TenancyLite[]>(url);
  }
  
  getTenantIdForCurrentUser(tenancyId: number): Observable<Tenant> {
    const url = `/api/lettings/tenancy/${tenancyId}/tenant/me`;
    return this._baseService.get<Tenant>(url);
  }

  upsert(tenancy: TenancyLite): Observable<number> {
    const url = (tenancy.id > 0) ? '/api/lettings/tenancy/update' : '/api/lettings/tenancy/insert';
    if (tenancy.id > 0) {
      return this._baseService.post<boolean>(url, tenancy).pipe(
        map(result => {
          if (result) {
            return tenancy.id;
          } else {
            throw new Error('Update failed');
          }
        })
      );
    } else {
      return this._baseService.post<number>(url, tenancy);
    }
  }


  // gets tenancies that the current user is a part of
  getUserTenancies(): Observable<TenancyProperty[]> {
    const url = `/api/lettings/tenancy/me`;
    return this._baseService.get<TenancyProperty[]>(url);
  }


  GetAllTenanciesByProperty(propertyId: number): Observable<Tenancy[]> {
    const url = `/api/lettings/tenancy/all-for-property/${propertyId}`;
    return this._baseService.get<Tenancy[]>(url);
  }
}
