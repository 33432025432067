import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tlp-app-base',
  templateUrl: './app-base.component.html',
  styleUrls: ['./app-base.component.css']
})

export abstract class AppBaseComponent {

  // Recursively find a parameter in the route hierarchy.
  findParameter(route: ActivatedRoute, paramName: string): string | null {
   if (route.snapshot.paramMap.has(paramName)) {
     return route.snapshot.paramMap.get(paramName);
   } else if (route.parent) {
     return this.findParameter(route.parent, paramName);
   } else {
     return null;
   }
 }

 //recursively find a parameter in the route hierarchy.
  findData(route: ActivatedRoute, paramName: string): string | null {
   if (route.snapshot.paramMap.has(paramName)) {
     return route.snapshot.paramMap.get(paramName);
   } else if (route.parent) {
     return this.findData(route.parent, paramName);
   } else {
     return null;
   }
 }

   // Helper method to create a FormData object from a data object.
   createFormData(data: any, formData: FormData = new FormData()): FormData {

    // Loop through each key in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (value instanceof File) {
          formData.append(key, value);
        } else if (Array.isArray(value)) {
          // Check if the array consists of files
          if (value.length > 0 && value[0] instanceof File) {
            for (let i = 0; i < value.length; i++) {
              formData.append(key, value[i]); // Appending each file with the same key
            }
          } else {
            // Handle arrays of other types
            for (let i = 0; i < value.length; i++) {
              formData.append(`${key}[]`, value[i]);
            }
          }
        } else if (typeof value === 'object' && value !== null) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  }

}
