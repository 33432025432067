import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.css'],
})
export class ValidationErrorComponent implements OnInit {
  @Input()   control: AbstractControl = new UntypedFormControl();
  // @Input() controlName!: string;
  @Input() validationErrors!: { [key: string]: string }; // Use a more specific type for validation errors
  // @Input() form!: FormGroup;

  // Whether to show the error message or not
  shouldDisplayError(): boolean {
    const control = this.control;//.form.controls[this.controlName];
    return control && control.invalid && (control.dirty || control.touched);
  }

  Object: any;
  get errorMessage(): string | null {
    const control = this.control; //form.controls[this.controlName];
    if (control && this.validationErrors) {
      const errors = control.errors;
      if (errors) {
        const firstKey = Object.keys(errors)[0];
        return this.validationErrors[firstKey];
      }
    }
    return null;
  }

  getErrorKeys(errors: ValidationErrors | null): string[] {
    return errors ? Object.keys(errors) : [];
  }

  ngOnInit(): void {
    // Removed console logs to clean up the component initialization
  }
}
