import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable, map, tap } from 'rxjs';
import { RegisterNewAgency } from '../../models/register-new-agency';
import { of } from 'rxjs';
import { APIResponse } from '@src/app/models/APIResponse';
import { User } from '../authentication/models';
import { LoginResponse } from '../authentication/models/LoginResponse';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private _baseService: BaseApiService) {  }

  // register new landlord  with api
  // registerNewAgency(registerNewAgency : RegisterNewAgency): Observable<boolean> {
  //   const url = '/api/account/register';
  //   let result = this._baseService.post<APIResponse<boolean>>(url, registerNewAgency);
  //   result.subscribe({  next: (result: APIResponse<boolean>) => {
  //     console.log('result', result);
  //     return of(result.Data);
  //   }
  // });
  // }

  registerNewAgency(registerNewAgency : RegisterNewAgency): Observable<boolean> {
    const url = '/api/account/register';
    return this._baseService.post<APIResponse<boolean>>(url, registerNewAgency).pipe(
      map(res => {
        if (!res.isSuccess) {
          this._baseService.handleAPINonSuccessResponse(res);
          //throw new Error(`Registration failed! ${JSON.stringify(res.errors)}`);
        }
        return res.data as boolean;
      }), // Extract the Data property from the APIResponse

    );
  }

  // is this used anywhere?
  registerNewUser(registerNewAgency : RegisterNewAgency): Observable<boolean> {
    const url = '/api/account/new-user';
    return this._baseService.post<APIResponse<boolean>>(url, registerNewAgency).pipe(
      map(res => {
        if (!res.isSuccess) {
          this._baseService.handleAPINonSuccessResponse(res);
          //throw new Error(`Registration failed! ${JSON.stringify(res.errors)}`);
        }
        return res.data as boolean;
      }), // Extract the Data property from the APIResponse
    );
  }

  verifyNewUserToken(verificationCode : string): Observable<RegisterNewAgency> {
    const url = '/api/account/verify-new-user-token';
    return this._baseService.post<APIResponse<RegisterNewAgency>>(url, {"verificationCode":verificationCode}).pipe(
      map(res => {
        if (!res.isSuccess) {
          this._baseService.handleAPINonSuccessResponse(res);
          //throw new Error(`Registration failed! ${JSON.stringify(res.errors)}`);
        }
        return res.data as RegisterNewAgency;
      }), // Extract the Data property from the APIResponse
    );
  }

  verifyNewUser(newAgency : RegisterNewAgency): Observable<LoginResponse> {
    const url = '/api/account/verify-new-user';
    return this._baseService.post<APIResponse<LoginResponse>>(url, newAgency).pipe(
      map(response => {
        if (!response.isSuccess) {
          this._baseService.handleAPINonSuccessResponse(response);
          //throw new Error(`Registration failed! ${JSON.stringify(res.errors)}`);
        }
        return response.data as LoginResponse;
      }), // Extract the Data property from the APIResponse
    );
  }



}
