import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'predicate',
    pure: false
})

// https://stackoverflow.com/questions/34164413/how-to-apply-filters-to-ngfor
// creates a pipe that takes a callback with the following signuature (item: any) => boolean
// <li *ngFor="let user of items | callback: filterUser">{{item.name}}</li>
export class PredicatePipe implements PipeTransform {
    transform(items: any[], predicate: (item: any) => boolean): any {
        if (!items || !predicate) {
            return items;
        }
        return items.filter(item => predicate(item));
    }
}
