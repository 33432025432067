<div class=" justify-content-center text-center  mt-4 row">
  <div *ngIf="!newAgency.firstName">
    <h2 class="col-12">
      <strong>Invalid or expired confirmation link </strong>
    </h2>
    <alert type="danger" class="alert alert-danger col-12">
      <strong>Error!</strong> Please resend the link.
    </alert>
  </div>
  <div *ngIf="newAgency.firstName">
    <h4 class="col-12 text-primary ">
      Welcome {{newAgency.firstName}} {{newAgency.lastName}}!!
    </h4>
      <p>Thank you for verifying your email address.</p>
      <p>Please complete your registration by filling in the form below</p>


  <div class="row justify-content-center mt-4">
    <div class="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
      <form [formGroup]="form" novalidate="novalidate">
        <div class="validation-summary-valid" data-valmsg-summary="true">
          <ul>
            <li class="d-none"></li>
          </ul>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 row">
              <input class="form-control" value="Owner" type="hidden" id="Role" name="Role">

              <control-wrapper name="email" label="Email address" [control]="form.controls['email']">
                <input class="form-control" type="email" formControlName="email"
                  placeholder="Please re-type your email address" [class.is-invalid]="isControlInvalid('email')">
                <app-validation-error [control]="form.controls['email']"
                  [validationErrors]="getValidationMessages('email')"></app-validation-error>
              </control-wrapper>

              <control-wrapper name="password" label="Password" [control]="form.controls['password']">
                <input class="form-control valid" type="password" data-val="true"
                  data-val-required="The Password field is required." id="Password" name="Password"
                  formControlName="password" [class.is-invalid]="isControlInvalid('password')">
                <app-validation-error [control]="form.controls['password']"
                  [validationErrors]="validationMessages['password']"></app-validation-error>
              </control-wrapper>
              <control-wrapper name="confirmPassword" label="Confirm password"
                [control]="form.controls['confirmPassword']">
                <input class="form-control" type="password" data-val="true"
                  data-val-equalto="The password and confirmation password do not match."
                  data-val-equalto-other="*.Password" formControlName="confirmPassword"
                  [class.is-invalid]="isControlInvalid('confirmPassword')">
                <app-validation-error [control]="form.controls['confirmPassword']"
                  [validationErrors]="validationMessages['confirmPassword']"></app-validation-error>
              </control-wrapper>

              <div class="row align-center center-block justify-content-center text-center  mt-4">
                <button type="submit" (click)="onSubmit()" class="btn btn-primary">Register</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
