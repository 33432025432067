import { Observer, Subject } from 'rxjs';
import { Observable } from 'rxjs';
// Class which models an event using observables.
export class Event<T> {

  private subject: Subject<T>;

  asObservable: () => Observable<T> = () => this.subject.asObservable();

  // Subscribe to the event
  subscribe = (fn: Partial<Observer<T>> | ((value: T) => void) | undefined) => this.subject.subscribe(fn);

  // Trigger the event.
  trigger = (item: T) => this.subject.next(item);

  // You can pass in ypur own Subject if alternative behaviour is required (ReplaySubject etc...).
  constructor(subject?: Subject<T>) {
    this.subject = subject || new Subject<T>();
  }
}
