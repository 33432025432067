import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdentityService } from '@core/authentication/services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent {
  constructor(private router: Router, private authService: UserIdentityService) {
    authService.logout();
    // this.router.navigate(['/']);
  }
}
