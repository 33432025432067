import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { UserService } from './core/services/user.service';

type StrategyFunction = (route: Route, load: () => Observable<any>) => Observable<any>;

interface Strategies {
  [key: string]: StrategyFunction;
}

@Injectable({
  providedIn: 'root'
})
export class AppCustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private userService: UserService) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preloadStrategy'] && typeof route.data['preloadStrategy'] === 'string') {
      const strategy = this.strategies[route.data['preloadStrategy'] as keyof Strategies];
      if (strategy) {
        return strategy(route, load);
      }
    }
    return of(null);
  }

  private strategies: Strategies = {
    maintenance: (route: Route, load: () => Observable<any>) => {
      if (this.userService.hasRole(['landlord'])) {
        return from(import('@landlord/features/maintenance/maintenance.module').then((m) => m.MaintenanceModule));
      } else if (this.userService.hasRole(['tenant'])) {
        return from(import('@landlord/features/maintenance/maintenance.module').then((m) => m.MaintenanceModule));
      } else {
        return from(import('@landlord/features/maintenance/maintenance.module').then((m) => m.MaintenanceModule));
      }
    },
    someOtherRoute: (route: Route, load: () => Observable<any>) => {
      // Logic for preloading someOtherRoute
      return of(null);
    },
    // ... more strategies
  };
}
