<ul class="nav flex-column">
    <li class="nav-item">
      <div class="nav-link" (click)="isRentMenuCollapsed = !isRentMenuCollapsed"
        [attr.aria-expanded]="!isRentMenuCollapsed" aria-controls="rentMenu" role="button">
        <span class="nav-link-icon"><span class="fa fa-money-bill-wave"></span></span><span
          class="nav-link-text ps-2">Rent & Bills</span>
      </div>
    </li>
    <li class="nav-item ms-3" id="rentMenu"  [collapse]="isRentMenuCollapsed">
      <a class="nav-link" routerLink="/payments/incoming" (click)="onMenuItemClick()" *ngIf="isLandlord">
        <div class="nav-link-content">
          <span class="nav-link-icon"><span class="fa fa-calendar-plus"></span></span><span
            class="nav-link-text ps-2">Incoming Payments</span>
        </div>
      </a>
      <a class="nav-link" routerLink="/payments/outgoing" (click)="onMenuItemClick()">
        <div class="nav-link-content">
          <span class="nav-link-icon"><span class="fa fa-calendar-minus"></span></span><span
            class="nav-link-text ps-2">Outgoing Payments</span>
        </div>
      </a>
      <a class="nav-link" routerLink="/payments/history" (click)="onMenuItemClick()">
        <div class="nav-link-content">
          <span class="nav-link-icon"><span class="fa fa-history"></span></span><span
            class="nav-link-text ps-2">Payment History</span>
        </div>
      </a>
    </li>
  </ul>