import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'tlp-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  @Input()
  control: AbstractControl = new UntypedFormControl();
  @Input()
  label : string = '';
  @Input()
  validationErrors: { [key: string]: string } | undefined = {};

  required: boolean = false;

  public validationMessages = {
    password: {
      required: 'Password is required',
      minlength: 'Password must be at least 8 characters long',
      weakPassword: 'Password must contain at least one uppercase letter, one number, and one special character',
      mismatch: 'Passwords do not match',
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      minlength: 'Confirm Password must be at least 8 characters long',
      mismatch: 'Passwords do not match',
    },
  };

  ngOnInit(): void {
    if (this.control?.validator && this.control?.validator(new UntypedFormControl(''))) {
      const errors = this.control.validator(new UntypedFormControl('')) || {};
      this.required = Object.keys(errors)?.includes('required');
    } else {
      this.required = false;
    }
    this.listenToStatusChanges();
  }

  public updateErrorMessages(): void {
    const passwordErrors = this.control.errors;
    if (passwordErrors) {
      this.setErrorMessageColor('default');
    } else {
      this.setErrorMessageColor('red');
    }
  }

  private setErrorMessageColor(color: string): void {
    const errorElements = document.querySelectorAll('.text-danger');
    errorElements.forEach((element) => {
      (element as HTMLElement).style.color = color;
    });
  }

  get errorMessage(): string | undefined {
    const control = this.control;
    if (control) {
      const errors = control.errors;
      if (errors) {
        const firstKey = Object.keys(errors)[0];
        return this.validationErrors ? this.validationErrors[firstKey] : undefined;
      }
    }
    return undefined;
  }

  private listenToStatusChanges(): void {
    if(this.control === null){
      console.error('Control is null');
      return
    }
    this.control?.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.addInvalidClasses();
      } else {
        this.removeInvalidClasses();
      }
    });
  }

  private removeInvalidClasses() {
    const el = document.getElementById(`${this.elementId}`);
    if (!el) return;
    const children = el.querySelectorAll('*');
    children.forEach((child) => {
      child.classList.remove('is-invalid');
    });
    el.classList.remove('is-invalid');
  }

  private addInvalidClasses() {
    const el = document.getElementById(`${this.elementId}`);
    if (!el) return;
    const children = el.querySelectorAll('*');
    children.forEach((child) => {
      child.classList.add('is-invalid');
    });
    el.classList.add('is-invalid');
  }

  public get elementId() {
    return `${Math.random().toString(36).substr(2)}-wrapper`;
  }

  shouldDisplayError(): boolean {
    const control = this.control;
    return this.control && control.invalid && (control.dirty || control.touched);
  }

  getErrorKeys(errors: ValidationErrors | null): string[] {
    return errors ? Object.keys(errors) : [];
  }

  // public updateErrorMessages(): void {
  //   const passwordErrors = this.control.errors;
  //   if (passwordErrors) {
  //     this.setErrorMessageColor('default');
  //   } else {
  //     this.setErrorMessageColor('red');
  //   }
  // }

  // private setErrorMessageColor(color: string): void {
  //   const errorElements = document.querySelectorAll('.text-danger');
  //   errorElements.forEach((element) => {
  //     (element as HTMLElement).style.color = color;
  //   });
  // }
}
