import { Component } from '@angular/core';

@Component({
  selector: 'recent-activity-navigation',
  templateUrl: './recent-activity-navigation.component.html',
  styleUrls: ['./recent-activity-navigation.component.css']
})
export class RecentActivityNavigationComponent {

}
