import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { APIResponse } from '@src/app/models/APIResponse';
import { ApiException } from '@src/app/models/system/ApiException';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  private baseUrl!: string;
  constructor(private http: HttpClient, private settingsService: SettingsService) {
    this.baseUrl = this.settingsService.get().apiBaseUri;
   }

  private setHeaders(httpHeaders: HttpHeaders | undefined): HttpHeaders {

    // let httpHeaders = new HttpHeaders();
    if (!httpHeaders || httpHeaders === undefined) {
      httpHeaders = new HttpHeaders();
    }
    return httpHeaders ?? new HttpHeaders();

    //  // Retrieve the authentication token from local storage
    //  const token = localStorage.getItem('token');

    //  // If the token exists, add the auth headers
    //  if (token) {
    //    const authHeader = `Bearer ${token}`;
    //    const userId = localStorage.getItem('user-id') || '';
    //    const agencyId = localStorage.getItem('agency-id') || '';

    //    httpHeaders = httpHeaders.append('Authorization', authHeader);
    //    httpHeaders = httpHeaders.append('user-id', userId);
    //    httpHeaders = httpHeaders.append('agency-id', agencyId);
    //  }

    // // Add application/json for every request if not set
    //  if(httpHeaders.get('Content-Type') === null || httpHeaders.get('Content-Type') === undefined)
    //   httpHeaders = httpHeaders.append( 'Content-Type', 'application/json; charset=utf-8');
    // return httpHeaders;
  }

  get<T>(url: string, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${url}`, { headers: this.setHeaders(headers) }).pipe(
      catchError(this.handleError)
    );
  }

  post<T>(url: string, data: any, options?: { headers?: HttpHeaders }): Observable<T> {

      let headers = options?.headers ?? new HttpHeaders();
      if (data instanceof FormData) {
        // data = JSON.stringify(data);
      // headers = new HttpHeaders({
      //   'Content-Type': 'multipart/form-data'
      // });
    //    headers.delete('ContentTtype'); // Remove the default application/json header
      // headers.set('Content-Type', 'application/json');
      //  headers.set('Content-Type', 'multipart/form-data');
      //   headers.set('Accept', "multipart/form-data");
    }

    headers = this.setHeaders(options?.headers);


    return this.http.post<T>(`${this.baseUrl}${url}`, data, { headers: headers }).pipe(
    catchError(this.handleError)
    );
    }

  // post<T>(url: string, data: any, options?: { headers?: HttpHeaders }): Observable<T> {
  //   const headers = this.setHeaders(options?.headers);
  //   headers.delete('Content-Type'); // Remove the default application/json header
  //   return this.http.post<T>(`${this.baseUrl}${url}`, data, { headers: this.setHeaders(options?.headers) }).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  delete<T>(url: string, headers?: HttpHeaders): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}${url}`, { headers: this.setHeaders(headers) }).pipe(
      catchError(this.handleError)
    );
  }

  // Handle API errors from the APIResponse that is returned from the API
   handleAPINonSuccessResponse<T>(APIResponse: APIResponse<T>): void {
    if (APIResponse.hasErrors) {
      const errors = APIResponse.errors.join('\n');
      throw new Error(errors);
    }
  }

  public handleError(response: HttpErrorResponse) {
    let errorMessage = 'There was a problem with your request.  Please try again later.';
    const apiResponse = response?.error as APIResponse<ApiException>;
    const apiException = apiResponse?.data as ApiException;
    if (apiException && apiException.hasOwnProperty('ErrorCode')) {
      errorMessage = `Error Code: ${apiException.errorDetailCode}\nMessage: ${apiException.message}`;
    } else if (apiResponse && apiResponse.hasOwnProperty('hasErrors')) {
      if (apiResponse.hasErrors) {
        errorMessage = apiResponse.errors.join('\n');
      }
    } else if (response.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `${response.error.message}`;
    } else {
      // Server-side errors
      if (response.error && response.error.Errors) {
        let combinedErrors = response.error.Errors.join('\n');
        errorMessage = `Error Code: ${response.status}\nMessage: ${combinedErrors}`;
      }
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
