import { Component, Input } from '@angular/core';
import { LettingPropertySummary } from '@src/app/models/LettingPropertySummary';

@Component({
  selector: 'tlp-property-summary-card',
  templateUrl: './property-summary-card.component.html',
  styleUrls: ['./property-summary-card.component.css']
})
export class PropertySummaryCardComponent {

  @Input()
  property: LettingPropertySummary  = {} as LettingPropertySummary;
  @Input()
  baseUrl: string  = '';

  ngOnit(): void {
    console.log(this.property);
  }
}
