import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from '@layout/main/footer/footer.component';
import { AppSidebarComponent } from '@layout/main/app-sidebar/app-sidebar.component';
import { RecentActivityNavigationComponent } from '@layout/recent-activity-navigation/recent-activity-navigation.component';
import { LayoutComponent } from '@layout/main/layout/layout.component';

import { AppModule } from '@src/app/app.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '@src/app/shared/shared.module';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { TenantSidebarComponent } from './tenant-sidebar/tenant-sidebar.component';
import { LandlordSidebarComponent } from './landlord-sidebar/landlord-sidebar.component';
import { RentAndBillsComponent } from './nav-items/rent-and-bills/rent-and-bills.component';

@NgModule({
  declarations: [
    FooterComponent,
    AppSidebarComponent,
    RecentActivityNavigationComponent,
    LayoutComponent,
    GlobalSearchComponent,
    TenantSidebarComponent,
    LandlordSidebarComponent,
    RentAndBillsComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    SharedModule,
    // AppModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  exports: [
    LayoutComponent,
    FooterComponent,
    AppSidebarComponent,
  ],
})
export class LayoutModule {}
