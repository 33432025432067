<div class="card border-primary h-100" [appClickableRow]="['/property', property.id]">
    <img [src]="baseUrl + '/api/images/' + property.mainImageFilepath + '?height=150'" class="card-img-top " alt="Property Image" style="width: 100%; height: 150px; object-fit: contain; object-position: center; padding-top: 10px;">
    <div class="card-body">
      <h6 class="">
        {{property.fullAddress}}
      </h6>
        <h6 class="text-primary">Rent: £{{ property.price | number:'1.2-2' }}</h6>
      
    </div>
    <div class="card-footer bg-primary text-white d-flex justify-content-between">
      <span><i class="fas fa-bed"></i> {{ property.bedrooms || 0 }}</span>
      <span><i class="fas fa-bath"></i> {{ property.bathrooms || 0}}</span>
    </div>
  </div>