import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Clone the request to add the new header
    request = request.clone({
      setHeaders: {
        // 'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest'
      }
    });

    // Retrieve the authentication token from local storage
    const token = localStorage.getItem('token');

    // If the token exists, add it to the request headers
    if (token) {
      const authHeader = `Bearer ${token}`;
      const userId = localStorage.getItem('user-id') || '';
      const agencyId = localStorage.getItem('agency-id') || '';
      request = request.clone({
        setHeaders: {
          'Authorization': authHeader,
          'user-id': userId,
          'agency-id': agencyId
        }
      });
    }

    // Pass the modified request to the next interceptor or the HTTP handler

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if(error.error?.ErrorCode === '4014004') {
              localStorage.removeItem('token');
              localStorage.removeItem('user-id');
              localStorage.removeItem('agency-id');
          }
           // Redirect to the login page with the referrer page
           const currentUrl = this.router.url;
           this.router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
        }
        return throwError(error);
      })
    );
  }
}
