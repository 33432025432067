import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { LoginResponse } from '@src/app/core/authentication/models/LoginResponse';
import { UserIdentityService } from '@src/app/core/authentication/services';
import { AccountService } from '@src/app/core/services/account.service';
import { RegisterNewAgency } from '@src/app/models/register-new-agency';
import { FormComponent } from '@src/app/shared/components/form/form/form.component';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
})
export class VerifyEmailComponent extends FormComponent implements OnInit {
  paramVerificationCode = '';

  form: UntypedFormGroup = new FormGroup({});
  newAgency: RegisterNewAgency = {} as RegisterNewAgency;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService: UserIdentityService,
    private router: Router,
  ) {
    super();

    this.route.queryParams.subscribe((params) => {
      this.paramVerificationCode = params['verificationCode'];
    });
  }

  override ngOnInit(): void {
    if (this.paramVerificationCode === '') {
      this.toastr.error('Invalid verification link');
      return;
    }
    this.verifyNewUserToken();
    // this.generateForm();
    // super.ngOnInit();
  }

  // check that the verification code are valid and match whats in the database
  verifyNewUserToken() {
    combineLatest([
      this.accountService.verifyNewUserToken(this.paramVerificationCode),
    ]).subscribe(([partAgencyFromApi]) => {
      if (partAgencyFromApi) {
        this.newAgency = partAgencyFromApi;
      }
      this.generateForm();
      super.ngOnInit();
    });
  }

  // ... rest of the component
  public generateForm(): void {
    // this.form = this.fb.group(this.newAgency);
    this.form = this.fb.group({
      // firstName: ['', [Validators.required]],
      // lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (!this.validateForm()) {
      this.toastr.error('Validation errors.');
      return;
    }
    console.log(this.newAgency);

    // Update the propertyDetails with the current form values
    this.newAgency = { ...this.newAgency, ...this.form.value };
    if(this.newAgency.password !== this.newAgency.confirmPassword){
      this.toastr.info('The password and confirm password are different.');
      return;
    }

    this.accountService.verifyNewUser(this.newAgency).subscribe({
      next: (response) => {

       //do auto login if success
       this.doAutoLogin(response);
      },
      error: (error) => {
        this.toastr.info(error);
      },
    });
  }

        //this is a duplicate of the login code from login.component.ts
  private doAutoLogin(response: LoginResponse) {

          // create user
          let updateResult = this.authService.createUserFromLoginResponse(response);
          if(updateResult){
            // this.authService.updateUser(user);
            this.router.navigateByUrl('/');
            this.toastr.success('Account verified and logged in.');
          }
        }


  override validationMessages: { [key: string]: { [key: string]: string } } = {
    firstName: {
      required: 'First name is required',
    },
    lastName: {
      required: 'Last name is required',
    },
    email: {
      required: 'Email is required',
      email: 'Email must be a valid email address',
    },
    password: {
      required: 'Password is required',
    },
    confirmPassword: {
      required: 'Confirm password is required',
    },
    // ... other validation messages ...
  };
}
