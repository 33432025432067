import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormComponent } from '@src/app/shared/components/form/form/form.component';
// import { ContactUsViewModel } from '@src/app/features/anonymous/models/ContactUsViewModel';
import { AccountService } from '@src/app/core/services/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent extends FormComponent implements OnInit {

  // private contactuUsViewModel: ContactUsViewModel;
  form: UntypedFormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {
    super();
  }


  override ngOnInit(): void {
    this.generateForm();
    super.ngOnInit();
  }

  // ... rest of the component
  public generateForm(): void {
    // this.form = this.fb.group(this.newAgency);
    this.form = this.fb.group({
      fullName: ['', [Validators.required]],
      telephone: ['', [Validators.required]],
      message: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  override onSubmit(): void {

    if (!this.validateForm()) {
      this.toastr.error('Validation errors.');
      return;
    }

    this.toastr.success('Thank you for contacting us.');
    this.toastr.error('Email not working at the moment.');
  }

  override validationMessages = {
    fullName: {
      required: 'Your full name is required',
    },
    message: {
      maxlength: 'Access Notes can have max 2500 characters',
    },
    telephone: {
      required: 'Your number is required',
    },
    email: {
      required: 'Email is required',
      email: 'Email must be a valid email address',
    },
  };

}
