import { Component } from '@angular/core';

@Component({
  selector: 'tlp-tenant-sidebar',
  templateUrl: './tenant-sidebar.component.html',
  styleUrls: ['./tenant-sidebar.component.css']
})
export class TenantSidebarComponent {

}
