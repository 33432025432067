<div class="container">
  <div class="card mx-auto" style="max-width: 400px; margin-top: 50px;">
    <div class="card-header text-bg-primary">
      <h2 class="card-title text-center">Forgot Password</h2>
    </div>
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <p>Please enter your email address. We will send a link to your inbox to reset your password.</p>
          <label for="email">Email</label>
          <input id="email" formControlName="email" class="form-control" />
          <div *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="text-danger">
            {{ validationMessages.email.email }}
          </div>
        </div>
        <div class="mb-3"></div>
        <button type="submit" class="btn btn-primary btn-block" [disabled]="form.invalid">Submit</button>
      </form>
      <p *ngIf="message" class="text-center mt-3">{{ message }}</p>
    </div>
  </div>
</div>
