import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { RouterModule } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'The Landlord Portal';
  constructor(private ngSelectConfig: NgSelectConfig) {

    // set up ng-select global config
    this.ngSelectConfig.notFoundText = 'Not found';
    this.ngSelectConfig.appendTo = 'body';

    // npx theme
    setTheme('bs5');
  }
}
