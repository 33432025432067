<div class="container py-3">
  <header>

    <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
      <h1 class="display-4 fw-normal">Pricing</h1>
      <p class="fs-5 text-muted">Choose a plan that suits your portfolio and as you grow, we can grow with you
        </p>
    </div>
  </header>

  <main>
    <div class="text-center mb-4">
      <label class="form-check-label" for="paymentToggle">Monthly</label>
      <ui-switch [(ngModel)]="isYearly" (change)="togglePayment()" class=""></ui-switch>
      <label class="form-check-label" for="paymentToggle">Yearly (20% discount)</label>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mb-3 text-center d-flex justify-content-center">
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm border-primary h-100 card-spacing">
          <div class="card-header py-3 text-white bg-primary border-primary">
            <h4 class="my-0 fw-normal">Free for ever</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">£{{ isYearly ? prices.free.yearly : prices.free.monthly }}<small class="text-muted fw-light">/{{ isYearly ? 'yr' : 'mo' }}</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>1 property</li>
              <li>5 tenancy</li>
              <li>100Mb of storage</li>
              <li>Email support</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
           </div>
          <div class="card-footer">
            <a routerLink="/register" class="w-100 btn btn-lg btn-primary text-white ">Sign up for free</a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm border-primary h-100">
          <div class="card-header py-3 text-white bg-primary border-primary">
            <h4 class="my-0 fw-normal">Starter</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">£{{ isYearly ? prices.starter.yearly : prices.starter.monthly }}<small class="text-muted fw-light">/{{ isYearly ? 'yr' : 'mo' }}</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>3 properties</li>
              <li>Multiple tenancies</li>
              <li>1 GB of storage</li>
              <li>Electric/Gas Cerficiate tracking</li>
              <li>Email support</li>
              <li>&nbsp;</li>
            </ul>
          </div>
          <div class="card-footer">
            <a routerLink="/register" class="w-100 btn btn-lg btn-primary text-white ">Sign up for free</a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm border-primary h-100">
          <div class="card-header py-3 text-white bg-primary border-primary">
            <h4 class="my-0 fw-normal">Pro</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">£{{ isYearly ? prices.pro.yearly : prices.pro.monthly }}<small class="text-muted fw-light">/{{ isYearly ? 'yr' : 'mo' }}</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>10 properties</li>
              <li>Multiple tenancies</li>
              <li>3 GB of storage</li>
              <li>Electric/Gas Cerficiate tracking</li>
              <!-- <li>Scheduled emails including rent reminders</li> -->
              <li>Priority email support</li>
            </ul>
          </div>
          <div class="card-footer">
            <a routerLink="/register" class="w-100 btn btn-lg btn-primary text-white ">Sign up for free</a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm border-primary h-100">
          <div class="card-header py-3 text-white bg-primary border-primary">
            <h4 class="my-0 fw-normal">Enterprise</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">£ {{ isYearly ? prices.enterprise.yearly : prices.enterprise.monthly }}</h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Over 10 properties</li>
              <li>Multiple tenancies</li>
              <li>10 GB of storage</li>
              <li>Electric/Gas Cerficiate tracking</li>
              <li>Scheduled emails including rent reminders</li>
              <li>Priority email support</li>
            </ul>
          </div>
          <div class="card-footer">
            <a routerLink="/welcome/contact-us" class="w-100 btn btn-lg btn-primary text-white ">Contact us</a>
          </div>
        </div>
      </div>
    </div>

    <h2 class="display-6 text-center mb-4">Compare plans</h2>

    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th style="width: 34%;"></th>
            <th style="width: 22%;">Free</th>
            <th style="width: 22%;">Starter</th>
            <th style="width: 22%;">Pro</th>
            <th style="width: 22%;">Enterprise</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" class="text-start">Number of properties</th>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg><i class="fa fa-tick"></i></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Number of tenancies</th>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <th scope="row" class="text-start">Electricty/Gas Certificate tracking</th>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Automated email reminders</th>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Access to referencing</th>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Priority e-mail support</th>
            <td></td>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>

</div>
