import { Component, ComponentRef, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppDialogContext } from '@src/app/shared/models/app-modal-context';
// import { SSDialogContext } from 'app/shared/models/ss-modal-config';
import { ModalFormFooterComponent } from '../modal-form-footer/modal-form-footer.component';
import { ComponentFactoryResolver, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Component for displaying the modal dialog.
 *
 * @export
 * @class ModalDialogComponent
 * @extends {ModalFormFooterComponent}
 * @implements {OnInit}
 */
@Component({
    selector: 'app-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent extends ModalFormFooterComponent implements OnInit {
  @ViewChild('childComponentContainer', { read: ViewContainerRef, static: true }) childComponentContainer: ViewContainerRef | undefined;

  context: AppDialogContext = new AppDialogContext();
  childComponentRef: ComponentRef<any> | undefined;

  @Output() childEvent = new EventEmitter<{ eventName: string, data: any }>();

    /* Modal reference */
    public modalRef!: BsModalRef;
    /* Message to be displayed */
    public message: string = '';
    // @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
    // dynamicComponentContainer!: ViewContainerRef;

    /* Stores the onClose event */
    public onClose: Subject<boolean> = new Subject();
    /* Sets the dialog to active or inactive */
    public active: boolean = false;

    @Output() confirmEvent = new EventEmitter<object>();

    constructor(
      public bsModalRef: BsModalRef,
       public modalService: BsModalService,
       private componentFactoryResolver: ComponentFactoryResolver) 
       {
      super();
      this.modalRef = bsModalRef;
       }

    /**
     * Initializes the component.
     *
     * @memberof ModalDialogComponent
     */

  public override ngOnInit(): void {
    console.log('ModalDialogComponent initialized with context:', this.context);
    this.loadChildComponent();
  }

  loadChildComponent() {
    if (this.context && this.context.childComponent) {
      this.childComponentContainer?.clear();
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.context.childComponent);
      this.childComponentRef = this.childComponentContainer?.createComponent(componentFactory);

      // Dynamically listen to all outputs of the child component
      if (this.childComponentRef?.instance) {
        Object.keys(this.childComponentRef.instance).forEach(key => {
          const property = this.childComponentRef?.instance[key];
          if (property instanceof EventEmitter) {
            property.subscribe((data: any) => {
              this.childEvent.emit({ eventName: key, data });
            });
          }
        });
      }
    }
  }
    // public override ngOnInit(): void {
    //   this.onClose = new Subject();
    //   this.active = true;

    //   // Initialize context with default values if not provided
    //   this.context = {
    //     ...{
    //       okBtnText: 'Yes',
    //       cancelBtnText: 'No',
    //       showHeaderClose: true,
    //       showOnlyCloseBtn: false,
    //       footerClass: 'confirm-modal-footer'
    //     },
    //     ...this.context
    //   };

    //   this.loadChildComponent();

    // }


    // ngAfterViewInit() {
    //   this.loadChildComponent();
    // }

    /**
     * When user presses submit button, this method is called.
     *
     * @memberof ModalDialogComponent
     */
    public confirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.childEvent.emit({ eventName: 'ok', data: this.childComponentRef?.instance });
        // this.confirmEvent.emit(this.childComponentRef); //send back a referencd to the child component
        this.bsModalRef.hide();
    }

    /**
     * When user presses cancel button, this method is called.
     *
     * @memberof ModalDialogComponent
     */
    public decline(): void {
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    /**
     * Hides the confirmation modal.
     *
     * @memberof ModalDialogComponent
     */
    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }


  ngOnDestroy() {
    if (this.childComponentRef) {
      this.childComponentRef.destroy();
    }
  }

}
