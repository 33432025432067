import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-form-footer',
  templateUrl: './modal-form-footer.component.html',
  styleUrls: ['./modal-form-footer.component.scss']
})
export class ModalFormFooterComponent implements OnInit {

  @Input()
  errorMessage: string = '';
  @Input()
  inProgress: boolean = false;
  @Input()
  requiredNote = true;
  @Input()
  saveText = 'Save';
  @Input()
  saveBtnClass = 'button-primary';
  @Input()
  cancelText = 'Cancel';
  @Output()
  /* eslint-disable-next-line @angular-eslint/no-output-native */
  close = new EventEmitter<void>();
  @Output()
  /* eslint-disable-next-line @angular-eslint/no-output-native */
  submit = new EventEmitter<void>();

  ngOnInit(): void { }
}
