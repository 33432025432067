<div class="container">
  <main role="main" class="pb-3">
    <div class="container">
      <div class="row justify-content-center text-center mt-4">
        <h1 class="mb-3 col-12">
          <strong>The Landlord Portal</strong>
        </h1>
        <img title="The Landlord Portal"
          src="/assets/images/logo.png"
          style="min-width: 150px;min-height: 150px; width: 10vw; height: 10vh; display: block; cursor: pointer;"
          onclick="window.location.href = '/';"
        />
        <div class="col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
</div>
